import {
    FETCH_USERS,
    DELETE_USER,
    GET_PROFILE,
    DELETE_USERS,
    INVITE_USER,
    INVITE_USER_RESET,
    LOADER_USERS_LIST,
    LOADER_USERS_FORM,
} from "./types";
import commonService from "../core_services/commonService";
import axios from "../core_services/interceptor";
import _ from "lodash";
const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
};

export const fetchUsersCreator = (response) => {
    let users = {
        data: [],
    };

    users.data = response ? response.data : [];

    return {
        type: FETCH_USERS,
        payload: response,
    };
};

export const getProfile = () => (dispatch) => {
    axios
        .get(commonService.getBaseUrlAndWorkspace() + "user/profile", {
            headers: headers,
        })
        .then((response) => {
            dispatch({
                type: GET_PROFILE,
                payload: response ? response.data : {},
            });
        });
};

export const fetchUsers = (page = 1) => {
    let size = commonService.getTotalResultsPerPage("user");
    return (dispatch) => {
        dispatch({ type: LOADER_USERS_LIST });
        axios
            .get(
                commonService.getBaseUrlAndWorkspace() +
                    "users?page=" +
                    page +
                    "&size=" +
                    size,
                {
                    headers: headers,
                }
            )
            .then(function (response) {
                dispatch(fetchUsersCreator(response));
            });
    };
};

export const deleteUser = (id) => (dispatch) => {
    axios
        .delete(commonService.getBaseUrlAndWorkspace() + "user/delete/" + id, {
            headers,
        })
        .then((response) => {
            dispatch({
                type: DELETE_USER,
                payload: {
                    data: {
                        id: id,
                        deleteStatus: true,
                        response: response,
                    },
                },
            });
        });
};

export const deleteUsers = (ids) => (dispatch) => {
    let mapIds = [];

    _.forEach(ids, (value, key) => {
        mapIds = _.concat(mapIds, value.id);
    });
    const data = {
        headers: headers,
        data: mapIds,
    };

    axios
        .delete(commonService.getBaseUrlAndWorkspace() + "user/delete", data)
        .then((response) => {
            dispatch({
                type: DELETE_USERS,
                payload: response.status,
            });
        })
        .catch((error) => {
            console.log(error);
        });
};

export const inviteUser = (formData) => (dispatch) => {
    dispatch({ type: LOADER_USERS_FORM });
    axios
        .post(
            commonService.getBaseUrlAndWorkspace() + "users/invitations",
            formData,
            { headers }
        )
        .then((response) => {
            dispatch({
                type: INVITE_USER,
                payload: {
                    inviteSuccess: true,
                    response: response,
                },
            });
        })
        .catch((e) => {
            console.log(e);
        });
};

export const resetInvitedUser = () => (dispatch) => {
    dispatch({
        type: INVITE_USER_RESET,
        payload: {
            inviteSuccess: false,
            response: null,
        },
    });
};
