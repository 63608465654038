/* eslint-disable */
export default {
    /**
     * Content
     */
    content: "Inhalt",
    contents: "Inhalte",
    contentsSelector: "Inhaltsauswahl",
    "contents.list": "Inhaltsverzeichnis",
    "contents.create": "Einen neuen Inhalt erstellen",
    "contents.import": "Inhalte aus einer Excel-Datei importieren",
    "content.delete": "Inhalt löschen",
    "contents.delete": "Inhalte löschen",
    "contents.edit": "Inhalt bearbeiten",
    "contents.select": "Ausgewählter Inhalt",
    "placeHolder.content.search": "Geben Sie einen Suchbegriff ein",
    "content.edited": "Inhalt wurde erfolgreich bearbeitet",
    "content.deleted": "Inhalt wurde erfolgreich gelöscht",
    "content.created": "Inhalt wurde erfolgreich erstellt",
    childContent: "Untergeordnete Inhalte",
    "deleteAll.contents": "Alle Inhalte löschen",

    /**
     * Content-Type
     */
    contentType: "Inhaltstyp",
    conentTypeField: "Inhaltstypen Feld",
    contentTypes: "Inhaltstypen",
    "contentType.list": "Inhaltstypen Liste",
    "contentType.new": "Neuer Inhaltstyp",
    "contentTypes.edit": "Inhaltstyp bearbeiten",
    "contentType.create": "Einen neuen Inhalt erstellen",
    "contentTypes.create": "Einen Inhalt dieses Typs erstellen",
    "contentType.delete": "Inhaltstyp löschen",
    "contentTypes.deleteAll": "Alle Inhaltstypen löschen",
    "contentTypes.import": "Inhalte aus einer Excel-Datei importieren",
    "contentTypes.delete": "Inhaltstyp löschen",
    "contentTypes.clear": "Alle Inhaltes dieses Typs löschen",
    contentTypeFieldName: "Inhaltstype Feldname",
    "deleteAll.contentTypeField": "Alle Inhaltstype-Felder löschen",
    contentTypeSelection: "Inhaltstyp auswählen",

    /**
     * Connector
     */
    authorization: "Autorisierung",
    "connector.requestPermission":
        "Wollen Sie wirklich AutLay Kampagnen Management System Zugriff gewähren?",
    "connector.selectWorkspace": "Kunde auswählen",
    "connector.authorize": "Autorisieren",
    "connector.close": "Schließen",
};
