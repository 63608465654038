import React from "react";
import { useDispatch } from "react-redux";
import { handleModal } from "../../core_actions/toasterActions";

function ToastItem(props) {
    const { _title, _detail, _response } = props;

    const dispatch = useDispatch();
    return (
        <div className="toastItem">
            {_title && <div className="toastItem__title">{_title}</div>}
            {_detail && (
                <>
                    <button
                        onClick={() => {
                            console.log(_response);
                            dispatch(handleModal(true, _response));
                        }}
                        className="toastItem__detail-btn"
                    >
                        Details
                    </button>
                </>
            )}
        </div>
    );
}

export default ToastItem;
