import axios from "axios";
import _ from "lodash";
import { Promise } from "q";
import { setToaster } from "../core_actions/toasterActions";
import { setLoader } from "../core_actions/loaderActions";
import store from "../../store";

//let configs = [];

let requestCounter = 0;
const uri = window._env_
    ? window._env_.REACT_APP_HOSTNAME
    : process.env.REACT_APP_HOSTNAME;
const { dispatch } = store;
axios.interceptors.request.use(
    async function (config) {
        requestCounter++;
        dispatch(setLoader(true));

        if (
            config.method !== "get" &&
            config.method !== "delete" &&
            typeof config.data === "object" &&
            !config.url.includes("excelFiles") &&
            !Array.isArray(config.data)
        ) {
            config.data = _.omit(
                config.data,
                "createdById",
                "createdAt",
                "modifiedById",
                "modifiedAt",
                "label"
            );
        }

        return config;
    },
    function (error) {
        // Do something with request error
        requestCounter--;
        if (requestCounter === 0) {
            dispatch(setLoader(false));
        }
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    async function (response) {
        // Do something with response data
        requestCounter--;
        if (requestCounter === 0) {
            dispatch(setLoader(false));
        }

        if (
            response.config.method !== "get" &&
            !(
                response.config.url.includes("/search") &&
                response.config.method === "post"
            )
        ) {
            dispatch(setToaster("success", "Success"));
        }

        if (response.config.url.includes("Refreshes")) {
            dispatch(setToaster("success", "Success"));
        }

        return response;
    },
    function (error) {
        // Do something with response error
        requestCounter--;
        if (requestCounter === 0) {
            dispatch(setLoader(false));
        }

        if (error.response && error.response.config.url.includes("Refreshes")) {
            dispatch(
                setToaster(
                    "error",
                    "Something went wrong. Please contact the admin"
                )
            );
        }
        const status = error.response ? error.response.status : null;
        const errorData = error.response ? error.response.data : null;
        if (status !== 401) {
            if (status === 403) {
                // sessionStorage.clear();
                // window.location.href = uri + "#/login";
            } else {
                if (error.message !== undefined) {
                    dispatch(setToaster("error", errorData));
                } else {
                    dispatch(
                        setToaster(
                            "error",
                            "Something went wrong. Please contact the admin"
                        )
                    );
                }
            }

            return error;
        } else if (status === 401) {
            sessionStorage.clear();
            window.location.href = uri + "#/login";
        }
    }
);

export default axios;
