import React from "react";
import commonService from "./commonService";
import tokenHandler from "./tokenHandling";

function withServices(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                toaster: "",
            };
            this.toaster = this.toaster.bind(this);
        }
        componentDidMount() {
            commonService.setAppContext(this);
            tokenHandler.startSessionTracking();
        }

        toaster(type, value, id, coolDown) {
            this.setState({
                toaster: commonService.toaster(type, value, id, coolDown),
            });
        }

        render() {
            return (
                <WrappedComponent
                    commonService={commonService}
                    toaster={this.toaster}
                    {...this.props}
                />
            );
        }
    };
}

export default withServices;
