import { HANDLE_MODAL, SET_TOASTER } from "../core_actions/types";

const initialState = {
    toaster: {},
    modalState: { bool: false, error: {} },
};
export default function toasterReducer(state = initialState, action) {
    switch (action.type) {
        case SET_TOASTER:
            return {
                ...state,
                toaster: {
                    type: action.payload.type,
                    value: action.payload.value,
                },
            };
        case HANDLE_MODAL:
            return {
                ...state,
                modalState: {
                    bool: action.payload.value,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
}
