export const LOCALE_SET = "LOCALE_SET";
export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";
export const FETCH_POST = "FETCH_POST";
export const NEW_POST = "NEW_POST";
export const SET_STATUS = "SET_STATUS";
export const REMOVE_STATUS = "REMOVE_STATUS";
export const SAVE_FORM = "SAVE_FORM";
export const UNSAFE_FORM = "UNSAFE_FORM";
export const SET_FORM_INPUT = "SET_FORM_INPUT";
export const GET_FORM_INPUTS = "GET_FORM_INPUTS";
export const INITIAL_FORM_INPUTS = "INITIAL_FORM_INPUTS";
export const INIT_FORM_VALIDATION = "INIT_FORM_VALIDATION";
export const UPDATE_FORM_VALIDATION = "UPDATE_FORM_VALIDATION";
export const SET_TOASTER_FUNCTION = "SET_TOASTER_FUNCTION";

export const SET_LOADER_VALUE = "SET_LOADER_VALUE";
export const SET_LOADER = "SET_LOADER";
export const SET_TOASTER = "SET_TOASTER";
export const HANDLE_MODAL = "HANDLE_MODAL";

export const FETCH_WORKSPACES = "FETCH_WORKSPACES";
export const FETCH_ALL_WORKSPACES = "FETCH_ALL_WORKSPACES";
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const GET_CURRENT_WORKSPACE = "GET_CURRENT_WORKSPACE";
export const FETCH_WORKSPACE = "FETCH_WORKSPACE";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const EDIT_WORKSPACE = "EDIT_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const DELETE_WORKSPACES = "DELETE_WORKSPACES";
export const CLEAR_WORKSPACE_FORM = "CLEAR_WORKSPACE_FORM";
export const LOADER_WORKSPACES_LIST = "LOADER_WORKSPACES_LIST";
export const LOADER_WORKSPACE_FORM = "LOADER_WORKSPACE_FORM";
export const LOADER_WORKSPACE_SELECTION = "LOADER_WORKSPACE_SELECTION";
export const LOADER_CURRENT_WORKSPACE_GET = "LOADER_CURRENT_WORKSPACE_GET";

export const REMOVE_DELETED_RECIPIENT = "REMOVE_DELETED_RECIPIENT";
export const SET_IMPORTED_RECIPIENT_FILE = "SET_IMPORTED_RECIPIENT_FILE";
export const REMOVE_IMPORTED_RECIPIENT_FILE = "REMOVE_IMPORTED_RECIPIENT_FILE";
export const UPLOAD_RECIPIENTS = "UPLOAD_RECIPIENTS";

export const FETCH_USERS = "FETCH_USERS";
export const GET_PROFILE = "GET_PROFILE";
export const SELECT_USER = "SELECT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const FETCH_USER = "FETCH_USER";
export const CREATE_USER = "CREATE_USER";
export const REGISTER_USER = "REGISTER_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_USERS = "DELETE_USERS";
export const CHANGE_PASSWORD_STATUS = "CHANGE_PASSWORD_STATUS";
export const CLEAN_USER_FORM = "CLEAN_USER_FORM";
export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_RESET = "INVITE_USER_RESET";
export const LOADER_USERS_LIST = "LOADER_USERS_LIST";
export const LOADER_USERS_FORM = "LOADER_USERS_FORM";

export const FETCH_SENDOUTS = "FETCH_SENDOUTS";
export const DELETE_SENDOUT = "DELETE_SENDOUT";
export const DELETE_SENDOUTS = "DELETE_SENDOUTS";
export const CREATE_SENDOUT = "CREATE_SENDOUT";
export const FETCH_SENDOUT = "FETCH_SENDOUT";
export const EDIT_SENDOUT = "EDIT_SENDOUT";
export const FETCH_OUTPUT_CONFIGS = "FETCH_OUTPUT_CONFIGS";
export const SET_SELECTED_RECIPIENTS = "SET_SELECTED_RECIPIENTS";
export const DELETE_ALL_SENDOUTS = "DELETE_ALL_SENDOUTS";
export const CLEAN_UP_CREATED_SENDOUT = "CLEAN_UP_CREATED_SENDOUT";

export const CLEAR_SENDOUTS = "CLEAR_SENDOUTS";
export const FETCH_SENDOUT_PAGES = "FETCH_SENDOUT_PAGES";
export const DELETE_SENDOUT_PAGE = "DELETE_SENDOUT_PAGE";
export const CREATE_SENDOUT_PAGE = "CREATE_SENDOUT_PAGE";
export const DELETE_SENDOUT_PAGES = "DELETE_SENDOUT_PAGES";
export const FETCH_SENDOUT_PAGE = "FETCH_SENDOUT_PAGE";
export const EDIT_SENDOUT_PAGE = "EDIT_SENDOUT_PAGE";
export const IMPORT_SENDOUT_PAGES = "IMPORT_SENDOUT_PAGES";
export const CLEAN_UP_IMPORTED_SENDOUT_PAGES =
    "CLEAN_UP_IMPORTED_SENDOUT_PAGES";
export const CLEAN_UP_CREATED_SENDOUT_PAGES =
    "CLEAN_UP_CREATED_SENDOUT_PAGES";
export const DELETE_ALL_SENDOUTS_PAGES = "DELETE_ALL_SENDOUTS_PAGES";
export const SET_IMPORTED_SENDOUT_PAGES_FILE =
    "SET_IMPORTED_SENDOUT_PAGES_FILE";
export const REMOVE_IMPORTED_SENDOUT_PAGES_FILE =
    "REMOVE_IMPORTED_SENDOUT_PAGES_FILE";

export const FETCH_CONTENT_GROUPS = "FETCH_CONTENT_GROUPS";
export const DELETE_CONTENT_GROUP = "DELETE_CONTENT_GROUP";
export const DELETE_CONTENT_GROUPS = "DELETE_CONTENT_GROUPS";
export const SET_CONTENT_GROUP_FORM_OPEN = "SET_CONTENT_GROUP_FORM_OPEN";
export const CREATE_CONTENT_GROUP = "CREATE_CONTENT_GROUP";
export const FETCH_CONTENT_GROUP = "FETCH_CONTENT_GROUP";
export const EDIT_CONTENT_GROUP = "EDIT_CONTENT_GROUP";
export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const CREATE_LAYOUT = "CREATE_LAYOUT";
export const CLEAN_LAYOUT = "CLEAN_LAYOUT";
export const DELETE_ALL_CONTENT_GROUPS = "DELETE_ALL_CONTENT_GROUPS";

export const CLEAR_JOBS = "CLEAR_JOBS";
export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOB = "FETCH_JOB";
export const FETCH_JOB_CHILDREN = "FETCH_JOB_CHILDREN";
export const DELETE_JOB = "DELETE_JOB";
export const DELETE_JOBS = "DELETE_JOBS";
export const DELETE_ALL_JOBS = "DELETE_ALL_JOBS";
export const REMOVE_JOB = "REMOVE_JOB";
export const FETCH_JOB_FILES = "FETCH_JOB_FILES";
export const DOWNLOAD_JOB_FILE = "DOWNLOAD_JOB_FILE";
export const GET_JOB_RESULT = "GET_JOB_RESULT";

export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const SET_SELECT = "SET_SELECT";
export const REMOVE_SELECT = "REMOVE_SELECT";
export const SET_REMOVE_SELECT = "SET_REMOVE_SELECT";
export const SELECT_ALL = "SELECT_ALL";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const RESET_SELECTS = "RESET_SELECTS";
export const REFRESH_SELECTS = "REFRESH_SELECTS";

export const GET_OUTPUT_CONFIGS = "GET_OUTPUT_CONFIGS";

export const IMPORT_CONTENT_SELECTOR = "IMPORT_CONTENT_SELECTOR";
export const SET_IMPORTED_CONTENT_SELECTOR_FILE =
    "SET_IMPORTED_CONTENT_SELECTOR_FILE";
export const REMOVE_IMPORTED_CONTENT_SELECTOR_FILE =
    "REMOVE_IMPORTED_CONTENT_SELECTOR_FILE";

export const SET_WIZARD_STEP = "SET_WIZARD_STEP";
export const SET_WIZARD_STEPS = "SET_WIZARD_STEPS";
export const CLEAN_WIZARD = "CLEAN_WIZARD";

export const SET_CSS = "SET_CSS";
export const CLEAN_CSS = "CLEAN_CSS";
export const POST_CSS = "POST_CSS";
export const FETCH_CSS_FILES = "FETCH_CSS_FILES";
export const GET_CSS_FILE = "GET_CSS_FILE";
export const DELTE_CSS_FILES = "DELETE_CSS_FILES";
export const DELTE_CSS_FILE = "DELETE_CSS_FILE";
export const DELTE_ALL_CSS_FILES = "DELETE_ALL_CSS_FILES";
export const PATCH_CSS_FILE = "PATCH_CSS_FILE";
