import { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchWorkspaces,
    getCurrentWorkspace,
    selectWorkspace,
    fetchAllWorkspaces,
} from "../../../core_actions/workspaceActions";
import { fetchWorkspace } from "../../../core_actions/workspaceFormActions";
import { CLEAR_WORKSPACE_FORM } from "../../../core_actions/types";
import _ from "lodash";

export const useHandleToken = () => {
    const accessToken = window.sessionStorage.getItem("access_token");
    const refreshToken = window.sessionStorage.getItem("refresh_token");
    const [validToken, setvalidToken] = useState(false);
    useEffect(() => {
        setvalidToken(accessToken !== null && refreshToken !== null);
    }, [accessToken, refreshToken]);
    return validToken;
};

export const useFetchWorkspaces = (fetch = true) => {
    const workspacesFromDb = useSelector(
        (state) => state.workspaces.workspaces
    );
    const [workspaces, setworkspaces] = useState([]);
    const [meta, setmeta] = useState(null);
    const dispatch = useDispatch();

    useMemo(() => {
        dispatch(fetchWorkspaces());
    }, [dispatch]);

    useMemo(() => {
        if (workspacesFromDb && workspacesFromDb.content) {
            const metaData = _.omit(workspacesFromDb, ["content"]);
            metaData.func = fetchWorkspaces;
            setworkspaces(workspacesFromDb.content);
            setmeta(metaData);
        }
        if (workspacesFromDb) {
            setworkspaces(workspacesFromDb);
        }
    }, [workspacesFromDb]);
    return [workspaces, meta];
};

export const useFetchWorkspacesFullList = (fetch = true) => {
    const workspacesFromDb = useSelector(
        (state) => state.workspaces.workspaceListAll
    );
    const [workspaces, setworkspaces] = useState([]);
    const [meta, setmeta] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (fetch) dispatch(fetchAllWorkspaces());
    }, [dispatch, fetch]);

    useEffect(() => {
        if (workspacesFromDb && workspacesFromDb.content) {
            const metaData = _.omit(workspacesFromDb, ["content"]);
            metaData.func = fetchAllWorkspaces;
            setworkspaces(workspacesFromDb.content);
            setmeta(metaData);
        }
        if (workspacesFromDb) {
            setworkspaces(workspacesFromDb);
        }
    }, [workspacesFromDb]);
    return [workspaces, meta];
};

export const useGetCurrentWorkspace = (fetch = true) => {
    const workspaceFromDb = useSelector(
        (state) => state.workspaces.currentWorkspace
    );
    const [workspace, setworkspace] = useState({});
    const dispatch = useDispatch();
    const validToken = useHandleToken();
    useEffect(() => {
        if (validToken && fetch) dispatch(getCurrentWorkspace());
    }, [dispatch, validToken, fetch]);

    useEffect(() => {
        if (workspaceFromDb && workspaceFromDb.data)
            setworkspace(workspaceFromDb.data);
    }, [workspaceFromDb]);

    return workspace;
};
export const useGetWorkspace = (id, fetch = true) => {
    const workspaceFromDb = useSelector(
        (state) => state.workspaceForm.workspace
    );
    const [workspace, setworkspace] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        return function cleanUp() {
            dispatch({ type: CLEAR_WORKSPACE_FORM });
        };
    }, [dispatch]);
    useEffect(() => {
        if (id) dispatch(fetchWorkspace(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (workspaceFromDb && workspaceFromDb.data)
            setworkspace(workspaceFromDb.data);
    }, [workspaceFromDb]);

    return workspace;
};

export const useSetCurrentWorkspace = (id) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(selectWorkspace(id));
    }, [dispatch, id]);
};
