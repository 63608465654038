import {
    FETCH_USERS,
    DELETE_USER,
    GET_PROFILE,
    DELETE_USERS,
    INVITE_USER,
    INVITE_USER_RESET,
    LOADER_USERS_LIST,
    LOADER_USERS_FORM,
} from "../core_actions/types";

const initialState = {
    requestStatus: {},
    users: {},
    profile: {},
    responseStatus: null,
    invitedUser: {
        inviteSuccess: false,
        response: null,
    },
    loaderUsersList: false,
    loaderUsersForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PROFILE: {
            return {
                ...state,
                profile: action.payload,
            };
        }
        case FETCH_USERS:
            return {
                ...state,
                users: action.payload,
                usersFromResponse: action.payload.data,
                responseStatus: null,
                loaderUsersList: false,
            };
        case DELETE_USER:
            return {
                ...state,
                deletedUser: action.payload,
            };
        case DELETE_USERS:
            return {
                ...state,
                responseStatus: action.payload,
            };
        case INVITE_USER:
            return {
                ...state,
                invitedUser: action.payload,
                loaderUsersForm: false,
            };
        case INVITE_USER_RESET:
            return {
                ...state,
                invitedUser: action.payload,
            };
        case LOADER_USERS_LIST:
            return {
                ...state,
                loaderUsersList: true,
            };
        case LOADER_USERS_FORM:
            return {
                ...state,
                loaderUsersForm: true,
            };
        default:
            return state;
    }
}
