import { ToastContainer as Toaster, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import React, { useEffect } from "react";
import ToastItem from "./ToastItem";

const ToastContainer = (props) => {
    const { _type, _value } = props;
    let errorData = {
        title: "Something went wrong!",
        detail: null,
    };
    useEffect(() => {
        if (_value?.title) {
            errorData.title = _value.title;
            errorData.detail = _value.detail;
        } else if (_value?.response) {
            errorData.title = _value.response.data.error;
        }

        switch (_type) {
            case "success":
                toast.success(_value, {
                    toastId: "success",
                });
                break;
            case "error":
                toast.error(
                    <ToastItem
                        _title={errorData.title}
                        _detail={errorData.detail}
                        _response={_value.response ? _value.response : _value}
                    />,
                    { autoClose: false }
                );
                break;
            case "info":
                toast.info(_value, {
                    toastId: "info",
                });
                break;
            case "warn":
                toast.warn(_value, {
                    toastId: "warn",
                });
                break;
            default:
                toast(_value);
        }
    });
    return (
        <div>
            <Toaster
                position="top-right"
                style={{
                    zIndex: 1999,
                }}
            />
        </div>
    );
};

export default ToastContainer;
