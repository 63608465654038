import {
    FETCH_WORKSPACES,
    DELETE_WORKSPACE,
    GET_CURRENT_WORKSPACE,
    SELECT_WORKSPACE,
    DELETE_WORKSPACES,
    FETCH_ALL_WORKSPACES,
    LOADER_WORKSPACES_LIST,
    LOADER_WORKSPACE_SELECTION,
    LOADER_CURRENT_WORKSPACE_GET,
} from "../core_actions/types";

const initialState = {
    requestStatus: {},
    workspaces: [],
    deletedWorkspace: {},
    deletedWorkspaces: [],
    currentWorkspace: {},
    selectedWorkspace: {},
    workspaceListAll: {},
    loaderWorkspacesList: false,
    loaderWorkspaceSelection: false,
    loaderCurrentWorkspaceGet: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_WORKSPACES:
            return {
                ...state,
                workspaces: action.payload,
                loaderWorkspacesList: false,
            };
        case DELETE_WORKSPACE:
            return {
                ...state,
                deletedWorkspace: action.payload,
                loaderWorkspacesList: false,
            };
        case DELETE_WORKSPACES:
            return {
                ...state,
                deletedWorkspaces: action.payload,
                loaderWorkspacesList: false,
            };
        case GET_CURRENT_WORKSPACE:
            return {
                ...state,
                currentWorkspace: action.payload,
            };
        case SELECT_WORKSPACE:
            return {
                ...state,
                selectedWorkspace: action.payload,
                loaderWorkspaceSelection: false,
            };
        case FETCH_ALL_WORKSPACES:
            return {
                ...state,
                workspaceListAll: action.payload,
            };
        case LOADER_WORKSPACES_LIST:
            return {
                ...state,
                loaderWorkspacesList: true,
            };
        case LOADER_WORKSPACE_SELECTION:
            return {
                ...state,
                loaderWorkspaceSelection: true,
            };
        case LOADER_CURRENT_WORKSPACE_GET:
            return {
                ...state,
                loaderCurrentWorkspaceGet: action.payload,
            };
        default:
            return state;
    }
}
