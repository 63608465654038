import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./cms/Reducers/index";
import logger from "redux-logger";

const initialState = {};
const env = process.env.NODE_ENV;
const middleWare = env === "development" ? [thunk, logger] : [thunk];

const store = getStore();
//for testing 
export function getStore(){
    let storeToCreate =
        env === "development"
            ? createStore(
                rootReducer,
                initialState,
                compose(
                    applyMiddleware(...middleWare),
                    window.__REDUX_DEVTOOLS_EXTENSION__
                        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
                                window.__REDUX_DEVTOOLS_EXTENSION__()
                        : (f) => f
                )
            )
            : createStore(
                rootReducer,
                initialState,
                compose(applyMiddleware(...middleWare))
            );
    return storeToCreate;
}

export default store;
