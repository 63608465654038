/* eslint-disable */
import {
    CREATE_WORKSPACE,
    FETCH_WORKSPACE,
    EDIT_WORKSPACE,
    CLEAR_WORKSPACE_FORM,
    LOADER_WORKSPACE_FORM,
} from "./types";
import commonService from "../core_services/commonService";
import axios from "../core_services/interceptor";

const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
};

export const clearWorkspaceForm = () => (dispatch) => {
    dispatch({
        type: CLEAR_WORKSPACE_FORM,
    });
};

export const createWorkspace = (data) => {
    return (dispatch) => {
        dispatch({ type: LOADER_WORKSPACE_FORM });
        axios
            .post(commonService.getBaseUrl() + "workspaceRegistrations", data, {
                headers: headers,
            })
            .then(function (response) {
                dispatch({
                    type: CREATE_WORKSPACE,
                    payload: response,
                });
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_WORKSPACE,
                    payload: error.response,
                });
            });
    };
};

export const fetchWorkspace = (id) => {
    return (dispatch) => {
        dispatch({ type: LOADER_WORKSPACE_FORM });
        let workspace = {
            data: [],
        };
        axios
            .get(commonService.getBaseUrl() + id, {
                headers: headers,
            })
            .then(function (response) {
                dispatch({
                    type: FETCH_WORKSPACE,
                    payload: response,
                });
            });
    };
};

export const editWorkspace = (id, formData) => {
    return (dispatch) => {
        dispatch({ type: LOADER_WORKSPACE_FORM });
        axios
            .put(commonService.getBaseUrl() + id, formData, {
                headers: headers,
            })
            .then(function (response) {
                dispatch({
                    type: EDIT_WORKSPACE,
                    payload: response,
                });
            });
    };
};
