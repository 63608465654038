export const LOCALE_SET = "LOCALE_SET";
export const FETCH_POST = "FETCH_POST";
export const NEW_POST = "NEW_POST";
export const SET_WINDOW_SIZE = "SET_WINDOW_SIZE";

export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const FETCH_CONTENT = "FETCH_CONTENT";
export const DELETE_CONTENTS = "DELETE_CONTENTS";
export const FETCH_CURRENT_CONTENTS = "FETCH_CURRENT_CONTENTS";
export const SORT_CONTENTS = "SORT_CONTENTS";
export const SEARCH_CONTENTS = "SEARCH_CONTENTS";
export const SET_STATUS = "SET_STATUS";
export const CLEAN_CONTENTS = "CLEAN_CONTENTS";

export const CREATE_CONTENT = "CREATE_CONTENT";
export const EDIT_CONTENT = "EDIT_CONTENT";
export const IMPORT_CONTENTS = "IMPORT_CONTENTS";
export const SET_IMPORTED_CONTENTS = "SET_IMPORTED_CONTENTS";
export const REMOVE_IMPORTED_CONTENTS = "REMOVE_IMPORTED_CONTENTS";
export const IMPORT_STATUS = "IMPORT_STATUS";
export const DELETE_MULTIPLE_CONTENTS = "DELETE_MULTIPLE_CONTENTS";
export const DELETE_ALL_CONTENTS = "DELETE_ALL_CONTENTS";
export const LOADER_CONTENTS_LIST = "LOADER_CONTENTS_LIST";
export const LOADER_CONTENTS_IMPORT = "LOADER_CONTENTS_IMPORT";
export const LOADER_CONTENT_FORM = "LOADER_CONTENT_FORM";

export const FETCH_CONTENT_TYPES = "FETCH_CONTENT_TYPES";
export const FETCH_CONTENT_TYPE = "FETCH_CONTENT_TYPE";
export const REMOVE_STATUS = "REMOVE_STATUS";
export const SET_CONTENT_TYPE_STATUS = "SET_CONTENT_TYPE_STATUS";
export const CLEAN_CONTENT_TYPES = "CLEAN_CONTENT_TYPES";
export const FETCH_CURRENT_CONTENT_TYPES = "FETCH_CURRENT_CONTENT_TYPES";
export const SORT_CONTENT_TYPES = "SORT_CONTENT_TYPES";
export const SEARCH_CONTENT_TYPES = "SEARCH_CONTENT_TYPES";
export const FETCH_CONTENT_TYPE_FIELDS = "FETCH_CONTENT_TYPE_FIELDS";
export const FETCH_CONTENT_TYPE_FIELD = "FETCH_CONTENT_TYPE_FIELD";
export const SET_NEW_CONTENT_TYPE_FIELDS = "SET_NEW_CONTENT_TYPE_FIELDS";
export const SET_NEW_CONTENT_TYPE_FIELD = "SET_NEW_CONTENT_TYPE_FIELD";
export const SET_EDIT_CONTENT_TYPE_FIELDS = "SET_EDIT_CONTENT_TYPE_FIELDS";
export const SET_DELETE_CONTENT_TYPE_FIELDS = "SET_DELETE_CONTENT_TYPE_FIELDS";
export const CREATE_CONTENT_TYPE = "CREATE_CONTENT_TYPE";
export const UPDATE_CONTENT_TYPE = "UPADTE_CONTENT_TYPE";
export const UPDATE_CONTENT_TYPE_FIELDS = "UPADTE_CONTENT_TYPE_FIELDS";
export const CREATE_CONTENT_TYPE_FIELDS = "CREATE_CONTENT_TYPE_FIELDS";
export const DELETE_CONTENT_TYPE_FIELDS = "DELETE_CONTENT_TYPE_FIELDS";
export const DELETE_CONTENT_TYPE = "DELETE_CONTENT_TYPE";
export const CLEAR_DELETE_CONTENT_TYPE = "CLEAR_DELETE_CONTENT_TYPE";
export const DELETE_CONTENT_TYPES = "DELETE_CONTENT_TYPES";
export const CLEAR_DELETE_CONTENT_TYPES = "CLEAR_DELETE_CONTENT_TYPES";
export const DELETE_ALL_CONTENT_TYPES = "DELETE_ALL_CONTENT_TYPES";
export const DELETE_ALL_CONTENT_TYPE_FIELDS = "DELETE_ALL_CONTENT_TYPE_FIELDS";
export const SET_LABEL_FIELD = "SET_LABEL_FIELD";
export const SET_CONTENT_TYPE_FORM_DATA = "SET_CONTENT_TYPE_FORM_DATA";
export const SET_CONTENT_TYPE_FIELD_DATA = "SET_CONTENT_TYPE_FIELD_DATA";
export const CLEAR_CONTENT_TYPE = "CLEAR_CONTENT_TYPE";
export const SET_READY = "SET_READY";
export const LOADER_CONTENT_TYPE_FORM = "LOADER_CONTENT_TYPE_FORM";
export const LOADER_CONTENT_TYPE_LIST = "LOADER_CONTENT_TYPE_LIST";

export const SAVE_FORM = "SAVE_FORM";
export const UNSAFE_FORM = "UNSAFE_FORM";
export const SET_FORM_INPUT = "SET_FORM_INPUT";
export const GET_FORM_INPUTS = "GET_FORM_INPUTS";
export const INITIAL_FORM_INPUTS = "INITIAL_FORM_INPUTS";
export const UPDATE_FORM_CONTENTS = "UPDATE_FORM_CONTENTS";
export const INIT_FORM_VALIDATION = "INIT_FORM_VALIDATION";
export const UPDATE_FORM_VALIDATION = "UPDATE_FORM_VALIDATION";

export const SET_LOADER_VALUE = "SET_LOADER_VALUE";

export const FETCH_WORKSPACES = "FETCH_WORKSPACES";
export const SELECT_WORKSPACE = "SELECT_WORKSPACE";
export const GET_CURRENT_WORKSPACE = "GET_CURRENT_WORKSPACE";
export const FETCH_WORKSPACE = "FETCH_WORKSPACE";
export const FETCH_CURRENT_WORKSPACES = "FETCH_CURRENT_WORKSPACES";
export const SORT_WORKSPACES = "SORT_WORKSPACES";
export const SEARCH_WORKSPACES = "SEARCH_WORKSPACES";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const EDIT_WORKSPACE = "EDIT_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const DELETE_WORKSPACES = "DELETE_WORKSPACES";
export const CLEAR_WORKSPACE_FORM = "CLEAR_WORKSPACE_FORM";

export const FETCH_USERS = "FETCH_USERS";
export const GET_PROFILE = "GET_PROFILE";
export const REGISTER_USER = "REGISTER_USER";
export const SELECT_USER = "SELECT_USER";
export const GET_CURRENT_USER = "GET_CURRENT_USER";
export const FETCH_USER = "FETCH_USER";
export const FETCH_CURRENT_USERS = "FETCH_CURRENT_USERS";
export const SORT_USERS = "SORT_USERS";
export const SEARCH_USERS = "SEARCH_USERS";
export const CREATE_USER = "CREATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const CHANGE_PASSWORD_STATUS = "CHANGE_PASSWORD_STATUS";
export const CLEAN_USER_FORM = "CLEAN_USER_FORM";

export const FETCH_CURRENT_PARENT_CONTENTS = "FETCH_CURRENT_PARENT_CONTENTS";
export const SORT_PARENT_CONTENTS = "SORT_PARENT_CONTENTS";
export const SEARCH_PARENT_CONTENTS = "SEARCH_PARENT_CONTENTS";
export const SELECTED_PARENT_CONTENTS = "SELECTED_PARENT_CONTENTS";
export const FETCH_PARENT_CONTENTS = "FETCH_PARENT_CONTENTS";
export const FETCH_CONTENT_PARENT_CONTENTS = "FETCH_CONTENT_PARENT_CONTENTS";
export const LOADER_PARENT_CONTENTS = "LOADER_PARENT_CONTENTS";

export const FETCH_CURRENT_CHILD_CONTENTS = "FETCH_CURRENT_CHILD_CONTENTS";
export const SORT_CHILD_CONTENTS = "SORT_CHILD_CONTENTS";
export const SEARCH_CHILD_CONTENTS = "SEARCH_CHILD_CONTENTS";
export const SELECTED_CHILD_CONTENTS = "SELECTED_CHILD_CONTENTS";
export const FETCH_CHILD_CONTENTS = "FETCH_CHILD_CONTENTS";
export const FETCH_CONTENT_CHILD_CONTENTS = "FETCH_CONTENT_CHILD_CONTENTS";
export const LOADER_CHILD_CONTENTS = "LOADER_CHILD_CONTENTS";

export const FETCH_CURRENT_CONTENTS_DIALOG = "FETCH_CURRENT_CONTENTS_DIALOG";
export const SORT_CONTENTS_DIALOG = "SORT_CONTENTS_DIALOG";
export const SEARCH_CONTENTS_DIALOG = "SEARCH_CONTENTS_DIALOG";

export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const SET_SELECT = "SET_SELECT";
export const REMOVE_SELECT = "REMOVE_SELECT";
export const SELECT_ALL = "SELECT_ALL";
export const SET_REMOVE_SELECT = "SET_REMOVE_SELECT";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const CONNECTOR_HANDSHAKE = "CONNECTOR_HANDSHAKE";
