const initialState = {
    sidebarShow: "responsive",
    asideShow: false,
    darkMode: null,
};

const coreUiReducer = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case "set":
            return { ...state, ...rest };
        default:
            return state;
    }
};

export default coreUiReducer;
