import { LOCALE_SET, SET_WINDOW_SIZE } from '../core_actions/types'

const initialState = {
    lang: "en",
    windowSize: 1200,
}
export default function localeReducer(state = initialState, action) {
    switch (action.type) {
        case LOCALE_SET:
            return {
                ...state,
                lang: action.lang
            }
        case SET_WINDOW_SIZE:
            return {
                ...state,
                windowSize: action.payload
            }
        default:
            return state;
    }
}
