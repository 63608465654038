export const logo = ['690 134', `

<g>
	<g>
		<rect x="35.24" y="1.16" fill="#FFFFFF" width="18.44" height="18.44"/>
		<rect x="45.35" y="11.27" fill="#E1930E" width="18.73" height="18.73"/>
		<rect x="56.62" fill="#FFFFFF" width="8.33" height="8.33"/>
		<rect x="45.35" y="11.27" fill="#F0C987" width="8.33" height="8.33"/>
		<rect x="35.24" y="21.67" fill="#E1930E" width="8.33" height="8.33"/>
	</g>
	<g>
		<g>
			<path fill="#FFFFFF" d="M89.47,18.35h-9.33l-1.77,4.17h-3.25l8.17-18.5h3.2l8.09,18.5h-3.33L89.47,18.35z M88.28,15.58
				l-3.49-8.22l-3.49,8.22H88.28z"/>
			<path fill="#FFFFFF" d="M108.92,8.47v14.06h-3.04v-2.75c-0.95,1.96-2.72,2.83-5.02,2.85c-3.22,0-5.15-2.06-5.15-5.42V8.47h3.04
				v7.9c0,2.11,1.24,3.41,3.28,3.41c2.43-0.05,3.86-1.93,3.86-4.39V8.47H108.92z"/>
			<path fill="#FFFFFF" d="M120.83,21.63c-1.11,0.66-2.27,1.03-3.46,1.03c-2.11,0-3.94-1.21-3.94-4.1v-7.29h-1.98V9h1.98V5.14h3.01
				V9h4.15v2.27h-4.15v6.74c0,1.45,0.56,1.9,1.51,1.9c0.63,0,1.3-0.21,2.11-0.61L120.83,21.63z"/>
			<polygon fill="#E1930E" points="126.93,4.03 126.93,19.7 134.94,19.7 134.94,22.53 123.82,22.53 123.82,4.03 			"/>
			<path fill="#E1930E" d="M146.17,22.53v-1.72c-1,1.24-2.62,1.85-4.65,1.85c-3.04,0-4.94-1.88-4.94-4.36c0-2.56,1.93-4.2,5.31-4.23
				h4.25v-0.42c0-1.77-1.14-2.83-3.36-2.83c-1.35,0-2.75,0.48-4.15,1.43l-1.24-2.11c1.96-1.16,3.38-1.77,6.05-1.77
				c3.62,0,5.65,1.85,5.68,4.94l0.03,9.22H146.17z M146.14,17.59v-1.3h-3.81c-1.98,0-2.91,0.53-2.91,1.88
				c0,1.27,1.03,2.09,2.72,2.09C144.32,20.26,145.98,19.12,146.14,17.59z"/>
			<path fill="#E1930E" d="M153.75,27.79c-1.27,0-2.27-0.37-3.25-1.19l1.3-2.35c0.66,0.48,1.19,0.69,1.82,0.69
				c0.93,0,1.67-0.5,2.14-1.56l0.48-1.06l-5.81-13.85h3.15l4.2,10.65l3.91-10.65h3.04l-6.4,15.99
				C157.45,26.68,155.87,27.79,153.75,27.79z"/>
		</g>
		<g>
			<path fill="#FFFFFF" d="M76.61,24.8h0.35l1.49,3.29h-0.4l-0.38-0.86h-1.78l-0.39,0.86h-0.38L76.61,24.8z M77.52,26.9l-0.74-1.67
				l-0.75,1.67H77.52z"/>
			<path fill="#FFFFFF" d="M79.2,27.18v-1.5h0.36v1.41c0,0.45,0.24,0.73,0.67,0.73c0.41,0,0.72-0.3,0.72-0.76v-1.38h0.35v2.42h-0.35
				v-0.42c-0.16,0.26-0.41,0.47-0.83,0.47C79.54,28.15,79.2,27.75,79.2,27.18z"/>
			<path fill="#FFFFFF" d="M82.49,27.45V26h-0.34v-0.32h0.34v-0.73h0.36v0.73h0.77V26h-0.77v1.41c0,0.29,0.16,0.4,0.41,0.4
				c0.12,0,0.22-0.02,0.35-0.08v0.31c-0.13,0.06-0.26,0.1-0.43,0.1C82.79,28.14,82.49,27.94,82.49,27.45z"/>
			<path fill="#FFFFFF" d="M84.29,26.89c0-0.68,0.53-1.26,1.26-1.26c0.72,0,1.26,0.57,1.26,1.26c0,0.68-0.54,1.26-1.27,1.26
				C84.82,28.15,84.29,27.58,84.29,26.89z M86.44,26.89c0-0.52-0.39-0.94-0.9-0.94c-0.52,0-0.88,0.42-0.88,0.94
				c0,0.52,0.38,0.94,0.89,0.94C86.07,27.83,86.44,27.41,86.44,26.89z"/>
			<path fill="#FFFFFF" d="M87.71,25.68h0.36v0.41c0.16-0.24,0.37-0.46,0.78-0.46c0.39,0,0.64,0.21,0.78,0.48
				c0.17-0.27,0.43-0.48,0.85-0.48c0.56,0,0.9,0.37,0.9,0.97v1.5h-0.36v-1.41c0-0.47-0.23-0.73-0.63-0.73
				c-0.36,0-0.67,0.27-0.67,0.75v1.39h-0.35v-1.42c0-0.45-0.24-0.72-0.62-0.72c-0.38,0-0.67,0.32-0.67,0.76v1.38h-0.36V25.68z"/>
			<path fill="#FFFFFF" d="M92.23,27.38c0-0.52,0.42-0.79,1.03-0.79c0.31,0,0.52,0.04,0.74,0.1v-0.08c0-0.43-0.26-0.65-0.72-0.65
				c-0.29,0-0.51,0.07-0.73,0.17l-0.11-0.29c0.27-0.12,0.52-0.21,0.88-0.21c0.34,0,0.61,0.09,0.78,0.27
				c0.17,0.17,0.25,0.4,0.25,0.71v1.48H94v-0.36c-0.17,0.22-0.44,0.41-0.87,0.41C92.67,28.15,92.23,27.89,92.23,27.38z M94,27.21
				v-0.22c-0.18-0.05-0.41-0.1-0.71-0.1c-0.45,0-0.71,0.2-0.71,0.5c0,0.3,0.28,0.48,0.61,0.48C93.64,27.86,94,27.59,94,27.21z"/>
			<path fill="#FFFFFF" d="M95.49,27.45V26h-0.34v-0.32h0.34v-0.73h0.36v0.73h0.77V26h-0.77v1.41c0,0.29,0.16,0.4,0.41,0.4
				c0.12,0,0.22-0.02,0.35-0.08v0.31c-0.13,0.06-0.26,0.1-0.43,0.1C95.79,28.14,95.49,27.94,95.49,27.45z"/>
			<path fill="#FFFFFF" d="M97.52,24.75h0.41v0.4h-0.41V24.75z M97.54,25.68h0.36v2.42h-0.36V25.68z"/>
			<path fill="#FFFFFF" d="M98.79,27.79l0.18-0.26c0.26,0.2,0.55,0.31,0.84,0.31c0.29,0,0.5-0.15,0.5-0.39
				c0-0.24-0.29-0.34-0.6-0.43c-0.38-0.11-0.8-0.24-0.8-0.69c0-0.42,0.35-0.69,0.82-0.69c0.29,0,0.62,0.1,0.87,0.27l-0.16,0.27
				c-0.22-0.15-0.48-0.23-0.71-0.23c-0.29,0-0.47,0.15-0.47,0.36c0,0.23,0.3,0.32,0.62,0.42c0.37,0.11,0.78,0.26,0.78,0.7
				c0,0.46-0.38,0.72-0.86,0.72C99.45,28.14,99.06,28.01,98.79,27.79z"/>
			<path fill="#FFFFFF" d="M101.42,26.89c0-0.68,0.53-1.26,1.24-1.26c0.46,0,0.74,0.19,0.97,0.43l-0.23,0.24
				c-0.19-0.2-0.42-0.35-0.74-0.35c-0.49,0-0.86,0.42-0.86,0.94c0,0.52,0.38,0.94,0.89,0.94c0.31,0,0.54-0.16,0.73-0.37l0.24,0.22
				c-0.24,0.27-0.53,0.47-0.99,0.47C101.96,28.15,101.42,27.58,101.42,26.89z"/>
			<path fill="#FFFFFF" d="M104.49,24.68h0.36v1.42c0.16-0.26,0.41-0.47,0.83-0.47c0.58,0,0.92,0.39,0.92,0.97v1.5h-0.36v-1.41
				c0-0.45-0.24-0.73-0.67-0.73c-0.42,0-0.72,0.3-0.72,0.76v1.38h-0.36V24.68z"/>
			<path fill="#FFFFFF" d="M108.69,27.84c0.33,0,0.57-0.14,0.76-0.34l0.22,0.2c-0.24,0.27-0.54,0.45-1,0.45
				c-0.66,0-1.21-0.51-1.21-1.26c0-0.7,0.49-1.26,1.16-1.26c0.71,0,1.13,0.57,1.13,1.28c0,0.03,0,0.07,0,0.13h-1.92
				C107.89,27.54,108.27,27.84,108.69,27.84z M109.4,26.74c-0.04-0.44-0.29-0.81-0.77-0.81c-0.42,0-0.73,0.35-0.79,0.81H109.4z"/>
			<path fill="#FFFFFF" d="M110.45,27.79l0.18-0.26c0.26,0.2,0.55,0.31,0.84,0.31c0.29,0,0.5-0.15,0.5-0.39
				c0-0.24-0.29-0.34-0.6-0.43c-0.38-0.11-0.8-0.24-0.8-0.69c0-0.42,0.35-0.69,0.82-0.69c0.29,0,0.62,0.1,0.87,0.27l-0.16,0.27
				c-0.22-0.15-0.48-0.23-0.71-0.23c-0.29,0-0.47,0.15-0.47,0.36c0,0.23,0.3,0.32,0.62,0.42c0.37,0.11,0.77,0.26,0.77,0.7
				c0,0.46-0.38,0.72-0.86,0.72C111.1,28.14,110.72,28.01,110.45,27.79z"/>
			<path fill="#FFFFFF" d="M114.94,24.82h0.37v2.93h1.84v0.34h-2.21V24.82z"/>
			<path fill="#FFFFFF" d="M117.85,27.38c0-0.52,0.42-0.79,1.03-0.79c0.31,0,0.52,0.04,0.74,0.1v-0.08c0-0.43-0.26-0.65-0.71-0.65
				c-0.29,0-0.51,0.07-0.73,0.17l-0.11-0.29c0.27-0.12,0.52-0.21,0.88-0.21c0.34,0,0.61,0.09,0.78,0.27
				c0.17,0.17,0.25,0.4,0.25,0.71v1.48h-0.36v-0.36c-0.17,0.22-0.44,0.41-0.87,0.41C118.3,28.15,117.85,27.89,117.85,27.38z
				 M119.62,27.21v-0.22c-0.18-0.05-0.41-0.1-0.71-0.1c-0.45,0-0.71,0.2-0.71,0.5c0,0.3,0.28,0.48,0.61,0.48
				C119.26,27.86,119.62,27.59,119.62,27.21z"/>
			<path fill="#FFFFFF" d="M120.76,28.73l0.12-0.28c0.12,0.06,0.22,0.09,0.38,0.09c0.22,0,0.35-0.11,0.5-0.45l-1.1-2.41h0.4
				l0.88,2.01l0.78-2.01h0.38l-1.01,2.5c-0.21,0.5-0.44,0.68-0.8,0.68C121.07,28.86,120.92,28.81,120.76,28.73z"/>
			<path fill="#FFFFFF" d="M123.62,26.89c0-0.68,0.53-1.26,1.26-1.26c0.72,0,1.26,0.57,1.26,1.26c0,0.68-0.54,1.26-1.27,1.26
				C124.15,28.15,123.62,27.58,123.62,26.89z M125.77,26.89c0-0.52-0.39-0.94-0.9-0.94c-0.52,0-0.88,0.42-0.88,0.94
				c0,0.52,0.38,0.94,0.89,0.94C125.41,27.83,125.77,27.41,125.77,26.89z"/>
			<path fill="#FFFFFF" d="M127.01,27.18v-1.5h0.36v1.41c0,0.45,0.24,0.73,0.67,0.73c0.41,0,0.72-0.3,0.72-0.76v-1.38h0.36v2.42
				h-0.36v-0.42c-0.16,0.26-0.41,0.47-0.83,0.47C127.35,28.15,127.01,27.75,127.01,27.18z"/>
			<path fill="#FFFFFF" d="M130.3,27.45V26h-0.34v-0.32h0.34v-0.73h0.36v0.73h0.77V26h-0.77v1.41c0,0.29,0.16,0.4,0.41,0.4
				c0.12,0,0.22-0.02,0.35-0.08v0.31c-0.13,0.06-0.26,0.1-0.43,0.1C130.6,28.14,130.3,27.94,130.3,27.45z"/>
			<path fill="#FFFFFF" d="M133.89,26.46c0-0.9,0.66-1.69,1.64-1.69c0.55,0,0.89,0.16,1.22,0.43l-0.24,0.28
				c-0.25-0.22-0.53-0.37-1-0.37c-0.72,0-1.24,0.61-1.24,1.35c0,0.78,0.5,1.36,1.29,1.36c0.37,0,0.72-0.14,0.94-0.33v-0.81h-1v-0.33
				h1.35v1.3c-0.3,0.27-0.76,0.5-1.31,0.5C134.52,28.15,133.89,27.4,133.89,26.46z"/>
			<path fill="#FFFFFF" d="M137.89,25.68h0.36v0.41c0.16-0.24,0.37-0.46,0.78-0.46c0.39,0,0.64,0.21,0.78,0.48
				c0.17-0.27,0.43-0.48,0.85-0.48c0.56,0,0.9,0.37,0.9,0.97v1.5h-0.36v-1.41c0-0.47-0.23-0.73-0.63-0.73
				c-0.36,0-0.67,0.27-0.67,0.75v1.39h-0.36v-1.42c0-0.45-0.24-0.72-0.62-0.72c-0.38,0-0.67,0.32-0.67,0.76v1.38h-0.36V25.68z"/>
			<path fill="#FFFFFF" d="M142.95,27.63v0.46h-0.36v-3.41h0.36v1.48c0.2-0.29,0.48-0.54,0.93-0.54c0.58,0,1.15,0.46,1.15,1.26
				c0,0.79-0.57,1.26-1.15,1.26C143.42,28.15,143.13,27.9,142.95,27.63z M144.66,26.88c0-0.57-0.39-0.94-0.85-0.94
				c-0.45,0-0.87,0.38-0.87,0.94c0,0.56,0.43,0.93,0.87,0.93C144.27,27.82,144.66,27.48,144.66,26.88z"/>
			<polygon fill="#FFFFFF" points="146,24.82 146.37,24.82 146.37,26.28 148.26,26.28 148.26,24.82 148.63,24.82 148.63,28.09
				148.26,28.09 148.26,26.62 146.37,26.62 146.37,28.09 146,28.09 			"/>
		</g>
		<g>
			<path fill="#FFFFFF" d="M154.13,5.64h0.44v0.5h-0.44V5.64z"/>
			<path fill="#FFFFFF" d="M155.1,4.9c0-0.7,0.55-1.29,1.27-1.29c0.47,0,0.76,0.2,0.99,0.44l-0.23,0.25
				c-0.2-0.21-0.43-0.36-0.76-0.36c-0.5,0-0.88,0.43-0.88,0.97c0,0.54,0.39,0.96,0.91,0.96c0.32,0,0.55-0.16,0.75-0.37l0.24,0.23
				c-0.25,0.28-0.55,0.48-1.01,0.48C155.65,6.2,155.1,5.61,155.1,4.9z"/>
			<path fill="#FFFFFF" d="M157.76,4.9c0-0.7,0.55-1.29,1.29-1.29c0.74,0,1.29,0.58,1.29,1.29c0,0.7-0.55,1.29-1.3,1.29
				C158.3,6.2,157.76,5.61,157.76,4.9z M159.97,4.9c0-0.53-0.4-0.97-0.92-0.97c-0.54,0-0.9,0.44-0.9,0.97
				c0,0.53,0.39,0.96,0.91,0.96C159.59,5.87,159.97,5.44,159.97,4.9z"/>
			<path fill="#FFFFFF" d="M161.01,3.66h0.37v0.42c0.16-0.24,0.38-0.47,0.8-0.47c0.4,0,0.66,0.22,0.8,0.49
				c0.18-0.27,0.44-0.49,0.87-0.49c0.57,0,0.92,0.38,0.92,1v1.53h-0.37V4.69c0-0.48-0.24-0.75-0.64-0.75
				c-0.37,0-0.68,0.28-0.68,0.77v1.43h-0.36V4.68c0-0.46-0.24-0.74-0.64-0.74c-0.39,0-0.69,0.33-0.69,0.78v1.41h-0.37V3.66z"/>
		</g>
	</g>
</g>
`]
