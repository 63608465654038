import axios from "axios";
import qs from "qs";
import moment from "moment";
import oauth from "axios-oauth-client";
const workspaceId = window._env_
    ? window._env_.REACT_APP_CLIENT_ID
    : process.env.REACT_APP_CLIENT_ID;
const uri = window._env_
    ? window._env_.REACT_APP_HOSTNAME
    : process.env.REACT_APP_HOSTNAME;

const authUrl = window._env_
    ? window._env_.REACT_APP_AUTH_DOMAIN
    : process.env.REACT_AUTH_DOMAIN;

const realm = window._env_
    ? window._env_.REACT_APP_KEYCLOAK_REALM
    : process.env.REACT_KEYCLOAK_REALM;
const intervalTime = 3000;

const authTokenEndpoint =
    authUrl + "realms/" + realm + "/protocol/openid-connect/token";
const authCodeEndpoint =
    authUrl + "realms/" + realm + "/protocol/openid-connect/auth";
const tokenHandler = {
    includesCode: () => {
        const code = sessionStorage.getItem("code");
        return !(code === null || code === undefined);
    },

    isAccessTokenValid: () => {
        const accessToken = sessionStorage.getItem("access_token");
        return !(accessToken === null || accessToken === undefined);
    },
    getAccessToken: function () {
        const codeToTokenExchange = oauth.client(axios.create(), {
            url: authTokenEndpoint,
            grant_type: "authorization_code",
            client_id: workspaceId,
            redirect_uri: uri,
            code: sessionStorage.getItem("code"),
        });
        const auth = codeToTokenExchange();
        auth.then(function (value) {
            window.sessionStorage.setItem("access_token", value.access_token);
            window.sessionStorage.setItem(
                "access_token_expires_in",
                value.expires_in
            );
            window.sessionStorage.setItem("refresh_token", value.refresh_token);
            window.location.href = uri + "#/";
        }).catch(function (error) {
            console.log("Auth went wrong!!!");
        });
    },
    authLogout: function () {
        const params = qs.stringify({
            client_id: workspaceId,
            refresh_token: sessionStorage.getItem("refresh_token"),
        });
        axios.post(this.authLogoutEndpoint, params);
    },
    getAuthorizationCode: function () {
        return (
            authCodeEndpoint +
            "?response_type=code&client_id=" +
            workspaceId +
            "&redirect_uri=" +
            uri
        );
    },
    generateTimeStamp: () => {
        let secs = sessionStorage.getItem("access_token_expires_in");
        if (secs) {
            let expired = moment().add(secs, "seconds");
            sessionStorage.setItem("expired", expired);
        }
    },
    getRefreshedToken: () => {
        let auth = oauth.client(axios.create(), {
            url: authTokenEndpoint,
            grant_type: "refresh_token",
            client_id: workspaceId,
            refresh_token: sessionStorage.getItem("refresh_token"),
        });
        return auth();
    },

    isTokenExpired: () => {
        const currentTime = new Date();
        const expired = sessionStorage.getItem("expired");
        // console.log(
        //     "current: " + moment(currentTime).format(),
        //     "expired: " + expired,
        //     "isExpired: " + moment(expired).diff(currentTime, "seconds")
        // );
        return moment(expired).diff(currentTime, "seconds") < 30;
    },
    startSessionTracking: function () {
        const expired = sessionStorage.getItem("expired");
        if (!expired) this.generateTimeStamp();
        setInterval(() => {
            const isExpired = this.isTokenExpired();
            if (isExpired) {
                const auth = this.getRefreshedToken();
                auth.then(function (value) {
                    sessionStorage.setItem("access_token", value.access_token);
                    sessionStorage.setItem(
                        "access_token_expires_in",
                        value.expires_in
                    );
                    sessionStorage.setItem(
                        "refresh_token",
                        value.refresh_token
                    );
                });

                this.generateTimeStamp();
            }
        }, intervalTime);
    },
};

export default tokenHandler;
