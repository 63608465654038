import {
    FETCH_CONTENTS,
    FETCH_USERS,
    FETCH_CURRENT_CONTENTS,
    SORT_CONTENTS,
    SEARCH_CONTENTS,
    DELETE_CONTENTS,
    FETCH_CONTENT,
    IMPORT_CONTENTS,
    SET_IMPORTED_CONTENTS,
    IMPORT_STATUS,
    REMOVE_IMPORTED_CONTENTS,
    DELETE_MULTIPLE_CONTENTS,
    DELETE_ALL_CONTENTS,
    EDIT_CONTENT,
    CLEAN_CONTENTS,
    LOADER_CONTENTS_LIST,
    LOADER_CONTENTS_IMPORT,
    LOADER_CONTENT_FORM,
    CREATE_CONTENT,
} from "../Actions/types";

const initialState = {
    requestStatus: {},
    users: [],
    content: {},
    contents: {},
    importedContents: {},
    editedContent: {},
    importStatus: -1,
    deletedContents: [],
    deletedAllContents: null,
    loaderContentsList: false,
    loaderContentsImport: false,
    loaderContentForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAN_CONTENTS:
            return {
                requestStatus: {},
                users: [],
                content: {},
                contents: {},
                importedContents: {},
                editedContent: {},
                importStatus: -1,
                deletedContents: [],
                deletedAllContents: null,
                loaderContentsList: false,
                loaderContentsImport: false,
                loaderContentForm: false,
            };
        case FETCH_CONTENTS:
            return {
                ...state,
                contents: action.payload,
                loaderContentsList: false,
            };
        case FETCH_USERS:
            return {
                ...state,
                users: action.payload,
            };
        case FETCH_CURRENT_CONTENTS:
            return {
                ...state,
                currentContents: action.payload,
            };
        case SORT_CONTENTS:
            return {
                ...state,
                sortedContents: action.payload,
            };
        case SEARCH_CONTENTS:
            return {
                ...state,
                filteredContents: action.payload,
            };
        case DELETE_ALL_CONTENTS: {
            return {
                ...state,
                deletedAllContents: action.payload,
                loaderContentsList: false,
            };
        }
        case DELETE_CONTENTS:
            return {
                ...state,
                deletedContent: action.payload,
                loaderContentsList: false,
            };
        case EDIT_CONTENT:
            return {
                ...state,
                editedContent: action.payload,
                loaderContentForm: false,
            };
        case DELETE_MULTIPLE_CONTENTS:
            return {
                ...state,
                deletedContents: action.payload,
                loaderContentsList: false,
            };
        case FETCH_CONTENT:
            return {
                ...state,
                content: action.payload,
                loaderContentForm: false,
            };
        case IMPORT_CONTENTS:
            return {
                ...state,
                importedContents: action.payload,
                loaderContentsImport: false,
            };

        case SET_IMPORTED_CONTENTS: {
            return {
                ...state,
                importedContents: action.payload,
            };
        }

        case REMOVE_IMPORTED_CONTENTS: {
            return {
                ...state,
                importedContents: {},
                importStatus: -1,
            };
        }
        case IMPORT_STATUS: {
            return {
                ...state,
                importStatus: action.payload,
            };
        }
        case LOADER_CONTENTS_LIST:
            return {
                ...state,
                loaderContentsList: true,
            };
        case LOADER_CONTENTS_IMPORT:
            return {
                ...state,
                loaderContentsImport: true,
            };
        case LOADER_CONTENT_FORM:
            return {
                ...state,
                loaderContentForm: true,
            };
        case CREATE_CONTENT:
            return {
                ...state,
                loaderContentForm: false,
            };
        default:
            return state;
    }
}
