export default {
    deleteSelected: "Ausgewählte löschen",

    /**
     * Content-Group
     */
    "deleteAll.contentGroups": "Alle Inhaltsgruppen löschen",
    "contentGroup.create": "Inhaltsgruppe erstellen",
    "contentGroup.edit": "Inhaltsgruppe bearbeiten",
    "contentGroup.delete": "Inhaltsgruppe löschen",
    "contentGroups.delete": "Inhaltsgruppen löschen",
    contentGroup: "Inhaltsgruppe",
    saveContentGroup: "Inhaltsgruppe speichern",

    /**
     * Workspace
     */
    workspaces: "Arbeitsbereiche",
    workspace: "Arbeitsbereich",
    workspaceName: "Arbeitsbereichname",
    "workspace.list": "Arbeitsbereichliste",
    "workspace.create": "Arbeitsbereich erstellen",
    "workspace.edit": "Arbeitsbereich bearbeiten",
    "workspace.delete": "Arbeitsbereich löschen",
    "workspaces.delete": "Arbeitsbereiche löschen",
    "deleteAll.workspace": "Alle Arbeitsbereiche löschen",
    "workspace.edited": "Arbeitsbereich wurde erfolgreich bearbeitet",
    "workspace.deleted": "Arbeitsbereich wurde erfolgreich gelöscht",
    "workspace.created": "Arbeitsbereich wurde erfolgreich erstellt",

    /**
     * User
     */
    users: "Benutzer",
    "user.list": "Benutzerliste",
    "user.create": "Benutzer erstellen",
    "user.edit": "Benutzer bearbeiten",
    "user.delete": "Benutzer löschen",
    "users.delete": "Benutzer löschen",
    "user.fullName": "Vollständiger Name",
    "user.userName": "Benutzername",
    "deleteAll.user": "Alle Benutzer löschen",
    "user.edited": "Benutzer wurde erfolgreich bearbeitet",
    "user.deleted": "Benutzer wurde erfolgreich gelöscht",
    "user.created": "Benutzer wurde erfolgreich erstellt",
    "user.invite": "Benutzer einladen",

    /**
     * Job
     */
    viewLayoutJob: "Layout Job ansehen",
    childLayoutJob: "Unteraufträge",
    parentLayoutJobId: "Oberauftrag",
    alsJobId: "ALS Auftrags-ID",
    alsJobStatus: "ALS Auftragsstatus",
    "job.delete": "Auftrag löschen",
    "jobs.delete": "Aufträge löschen",
    "deleteAll.job": "Alle Jobs löschen",
    "job.edited": "Job wurde erfolgreich bearbeitet",
    "job.deleted": "Job wurde erfolgreich gelöscht",
    "job.created": "Job wurde erfolgreich erstellt",

    /**
     * Session
     */
    "session.expiry": "Ihre Sitzung läuft ab in ",
    newSession: "Neue Sitzung",
    "session.expire": "Ihre Sitzung läuft ab in",
    "sessionTimeOut.pre": "Ihre Sitzung läuft ab in ",
    "sessionTimeOut.post":
        " Bitte starten Sie eine neue Sitzung, um fortzufahren.",
    "sessionTimeOut.minutes": " Minuten & ",
    "sessionTimeOut.seconds": " Sekunden.",

    /**
     * Input
     */
    foreignSystemId: "Fremdsystem Id",
    productId: "Artikelnummer",
    subtitle: "Untertitel",
    description: "Beschreibung",
    currency: "Währung",
    link: "Link URL",
    priceGross: "Bruttopreis",
    externalImage: "Externe Bild URL",
    specialOfferGross: "Brutto-Sonderpreis",
    imageUrl: "Bild URL",
    inputType: "Eingabe Typen",
    select: "Auswählen",
    "placeHolder.foreignSystemId": "Fremdsystem Id eingeben",
    "placeHolder.name": "Namen eingeben",
    "placeHolder.firstName": "Vornamen eingeben",
    "placeHolder.surname": "Nachnamen eingeben",
    "placeHolder.mail": "Mail eingeben",
    "placeHolder.street": "Straße eingeben",
    "placeHolder.zipCode": "Postleitzahl eingeben",
    "placeHolder.city": "Stadt eingeben",
    "placeHolder.country": "Land eingeben",
    "placeHolder.salutation": "Grußformel eingeben",
    "placeHolder.voucherCode": "Gutscheincode eingeben",
    "placeHolder.productId": "Artikelnummer eingeben",
    "placeHolder.subtitle": "Untertitel eingeben",
    "placeHolder.description": "Beschreibung eingeben",
    "placeHolder.currency": "Währung eingeben",
    "placeHolder.priceGross": "Bruttopreis eingeben",
    "placeHolder.link": "Link URL eingeben",
    "placeHolder.externalImage": "Externe Bild URL eingeben",
    "placeHolder.specialOfferGross": "Brutto-Sonderpreis eingeben",
    "placeHolder.email": "E-Mail eingeben",
    "placeholder.title": "Titel eingeben",
    "select.language": "Sprache auswählen",
    "error.input.invalidName": "Ungültiger Name",
    "error.input.invalidImageUrl": "Ungültige Bild URL",
    "error.input.default": "Ungültige Eingabe",

    /**
     * Table
     */
    slNum: "#",
    icon: "Icon",
    label: "Bezeichnung",
    name: "Name",
    modifiedAt: "Geändert am",
    modifiedBy: "Geändert von",
    createdAt: "Erstellt am",
    createdBy: "Erstellt von",
    search: "Suche",
    surname: "Nachname",
    firstName: "Vorname",
    eMail: "E-Mail",
    "table.search": "Suchen",
    itemsPerPage: "Ergebnisse pro Seite",

    /**
     * Header
     */
    account: "Konto",
    logout: "Abmelden",

    /**
     * Alert
     */
    "confirm.message": "Sind sie sicher, dass sie den Eintrag löschen wollen?",
    "confirm.yes": "Ja",
    "confirm.no": "Nein",

    /**
     * Login
     */
    welcomeText: "deutscher Willkommenstext...",
    goToLogin: "zum Login",

    /**
     * Wizard
     */
    "sendout.check": "Kampagne überprüfen",
    "layout.generate": "Layouts generieren",
    "steps.completed": "Alle Schritte wurden erfolgreich ausgeführt",
    productSelection: "Produktauswahl",
    catalog: "Katalog",

    /**
     * File
     */
    "upload.file": "Datei hochladen",
    file: "Datei",
    "file.reject": "Abgelehnter Dateityp!",
    "file.unknown":
        "Dateityp wurde nicht erkannt! Es kann sein, dass diese Datei nicht mit dem System kompatibel ist.",

    /**
     * Other
     */
    dashboard: "Übersichtsseite",
    register: "Registrieren",
    password: "Passwort",
    "password.change": "Passwort ändern",
    "password.old": "Altes Passwort",
    "password.new": "Neues Passwort",
    "password.confirm": "Passwort bestätigen",
    back: "Zurück",
    error: "Fehler",
    detailView: "Detailansicht",
    welcome: "Willkommen",
    title: "Titel",
    foreignId: "Fremdsystem Id",
    street: "Straße",
    zipCode: "PLZ",
    city: "Stadt",
    country: "Land",
    mail: "E-Mail",
    salutation: "Grußformel",
    voucherCode: "Gutscheincode",
    voucherExpirationDate: "Gültigkeitsdatum Gutscheincode",
    home: "Home",
    isRequired: "ist erforderlich",
    useForAls: "für ALS verwendet",
    resultsPerPage: "Ergebnisse pro Seite ",
    newProduct: "Neuer Inhalt: Produkt",
    save: "Speichern",
    goToList: "Zur Liste",
    noData: "Keine Daten vorhanden",
    create: "Erstellen",
    confirm: "Bestätigen",
    reset: "Zurücksetzen",
    deleteAll: "Sind Sie sicher, dass Sie alle Artikel löschen möchten",
    import: "Elemente aus einer Datei importieren",
    minPageCount: "Minimale Seitenzahl",
    maxPageCount: "Maximale Seitenzahl",
    "delete.all": "Sind sie sicher, dass sie alle Einträge löschen wollen?",
    "delete.selected":
        "Sind sie sicher, dass sie alle ausgewählten Einträge löschen wollen?",
    "delete.single": "Sind sie sicher, dass sie diesen Eintag löschen wollen?",
    outputConfig: "Ausgangskonfiguration",
    cancel: "Abbrechen",
    createNew: "Neu erstellen",
    drag: "Drag and Drop oder zum Durchsuchen anklicken",
    "selected.pre": "Du hast",
    "selected.post": "Einträge ausgewählt!",
    "sendout.confirmed": "Kampagne wurde bestätigt",
    "password.edited": "Passwort wurde erfolgreich geändert",
    "import.success": "Erfolgreicher Import",
    "error.unknown": "Etwas ist schief gelaufen :(",
    german: "Deutsch",
    english: "Englisch",
    invite: "Einladen",
    unavailable: "Unbekannt",
    adminstration: "Verwaltung",
    delete: "Löschung erfolgreich",
};
