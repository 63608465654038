import {  FETCH_CURRENT_CONTENTS_DIALOG, SEARCH_CONTENTS_DIALOG, SORT_CONTENTS_DIALOG } from '../Actions/types';

const initialState = {
    requestStatus: {},
}

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CURRENT_CONTENTS_DIALOG:
            return {
                ...state,
                currentDiaolgContents: action.payload
            }
        case SORT_CONTENTS_DIALOG:
            return {
                ...state,
                sortedDialogContents: action.payload
            }
        case SEARCH_CONTENTS_DIALOG:
            return {
                ...state,
                filteredDialogContents: action.payload
            }
        default:
            return state;
    }
}