/* eslint-disable */
export default {
    noData: "No data available",
    deleteAll: "Are you sure you wish to delete all items?",
    deleteAll: "Delete All",
    create: "Create",
    confirm: "Confirm",
    reset: "Reset",
    minPageCount: "Minimum Page Count",
    maxPageCount: "Maximum Page Count",
    outputConfig: "Output Configuration",
    cancel: "Cancel",
    createNew: "Create New",
    deleteSelected: "Delete selected",
    "delete.all": "Are you sure you wish to delete all items?",
    "delete.selected": "Are you sure you wish to delete all selected items?",
    "delete.single": "Are you sure you wish to delete this item?",
    drag: "Drag and Drop or click to browse",
    "selected.pre": "You have selected",
    "selected.post": "entries!",
    "password.edited": "Password changed Successfully",
    "import.success": "Import success",
    "error.unknown": "Something went wrong :(",

    /**
     * Content-Group
     */
    "contentGroup.create": "Create Content Group",
    "contentGroup.edit": "Edit Content Group",
    "contentGroup.delete": "Delete Content Group",
    "contentGroup.deletes": "Delete Content Groups",
    contentGroup: "Content Group",
    "deleteAll.contentGroups": "Delete all content groups",
    saveContentGroup: "Save Content Group",

    /**
     * Workspace
     */
    workspaces: "Workspaces",
    workspace: "Workspace",
    workspaceName: "ClieWorkspacent name",
    "deleteAll.workspace": "Delete all workspaces",
    "workspace.list": "Workspace List",
    "workspace.create": "Create Workspace",
    "workspace.edit": "Edit Workspace",
    "workspace.create": "Create a Workspace",
    "workspace.delete": "Delete Workspace",
    "workspaces.delete": "Delete Workspaces",
    "workspace.edit": "Edit Workspace",
    "workspace.edited": "Workspace edited Successfully",
    "workspace.deleted": "Workspace deleted Successfully",
    "workspace.created": "Workspace created Successfully",

    /**
     * User
     */
    users: "Users",
    "deleteAll.user": "Delete all users",
    "user.list": "User List",
    "user.create": "Create User",
    "user.edit": "Edit User",
    "user.fullName": "Full Name",
    "user.userName": "User Name",
    "user.delete": "Delete User",
    "users.delete": "Delete Users",
    "user.edit": "Edit User",
    "user.create": "Create user",
    "user.edited": "User edited Successfully",
    "user.deleted": "User deleted Successfully",
    "user.created": "User created Successfully",
    "user.invite": "Invite User",

    /**
     * Job
     */
    viewLayoutJob: "View layout job",
    childLayoutJob: "Child layout job",
    "job.edited": "Job edited Successfully",
    "job.deleted": "Job deleted Successfully",
    "job.created": "Job created Successfully",
    "job.delete": "Delete Job",
    "jobs.delete": "Delete Jobs",
    "deleteAll.job": "Delete all jobs",
    "label.parentLayoutJobId": "Parent layout job",

    /**
     * Session
     */
    newSession: "New Session",
    "session.expiry": "Your session will expire in ",
    "sessionTimeOut.pre": "Your session will expire in ",
    "sessionTimeOut.post": " Please start a new session to continue.",
    "sessionTimeOut.minutes": " minutes & ",
    "sessionTimeOut.seconds": " seconds.",
    "session.expire": "Your session will expire in ",

    /**
     * Input
     */
    foreignSystemId: "Foreign System Id",
    productId: "Product ID",
    subtitle: "Subtitle",
    description: "Description",
    currency: "Currency",
    priceGross: "Price gross",
    link: "Link URL",
    externalImage: "external Image URL",
    specialOfferGross: "special offer gross",

    "placeHolder.foreignSystemId": "Insert foreign system ID",
    "placeHolder.title": "Insert title",
    "placeHolder.name": "Insert name",
    "placeHolder.firstName": "Insert first name",
    "placeHolder.surname": "Insert surname",
    "placeHolder.email": "Insert Email Id",
    "placeHolder.mail": "Insert Mail",
    "placeHolder.street": "Insert Street",
    "placeHolder.zipCode": "Insert Zip Code",
    "placeHolder.city": "Insert City",
    "placeHolder.country": "Insert Country",
    "placeHolder.salutation": "Insert Salutation",
    "placeHolder.voucherCode": "Insert Voucher Code",

    "placeHolder.productId": "Insert Product ID",
    "placeHolder.subtitle": "Insert Subtitle",
    "placeHolder.description": "Insert Description",
    "placeHolder.currency": "Insert Currency",
    "placeHolder.priceGross": "Insert price gross",
    "placeHolder.link": "Insert link URL",
    "placeHolder.externalImage": "Insert external image URL",
    "placeHolder.specialOfferGross": "Insert special offer gross",
    "select.language": "Select Language",
    "error.invalidName": "Invalid name",
    "error.invalidImageUrl": "Invalid image URL",
    "error.input.default": "Invalid input",
    "label.imageUrl": "Image URL",
    "label.inputType": "Inputtypes",
    select: "Select",

    /**
     * Table
     */
    slNum: "#",
    icon: "Icon",
    label: "Label",
    name: "Name",
    modifiedAt: "Modified At",
    modifiedBy: "Modified By",
    createdAt: "Created At",
    createdBy: "Created By",
    search: "Search",
    surname: "Surname",
    firstName: "First Name",
    eMail: "E-Mail",
    alsJobId: "ALS Job ID",
    alsJobStatus: "ALS Job Status",
    itemsPerPage: "Items per page",

    /**
     * Header
     */
    account: "Account",
    logout: "Logout",

    /**
     * Alert
     */
    "confirm.message": "Are you sure you wish to delete this item?",
    "confirm.yes": "Yes",
    "confirm.no": "No",

    /**
     * Login
     */
    welcomeText: "english welcome text... ",
    goToLogin: "go to login",

    /**
     * Wizard
     */
    "layout.generate": "Generate layouts",
    "steps.completed": "All steps were executed successfully",
    "sendout.check": "Check Campaign",
    "sendoutPage.create": "Create Campaign page",
    "sendoutPage.mapContents": "Assign custom content",
    productSelection: "Produktauswahl",

    /**
     * File
     */
    "upload.file": "Upload file",
    file: "File",
    "file.reject": "Rejected File-type!",
    "file.unknown":
        "File type was not detected! It could be that this file is not compatible with the system.",
    import: "Import items from a file",
    catalog: "Catalog",

    /**
     * Other
     */
    dashboard: "Dashboard",
    register: "Register",
    password: "Password",
    changePassword: "Change password",
    "password.old": "Old password",
    "password.new": "New password",
    "password.confirm": "Confirm password",
    back: "Back",
    error: "Error",
    detailView: "Detail view",
    welcome: "Welcome",
    home: "Home",
    account: "Account",
    title: "Title",
    firstName: "First name",
    surname: "Surname",
    foreignId: "Foreign system Id",
    street: "Street",
    zipCode: "Zip Code",
    city: "City",
    country: "Country",
    mail: "Mail",
    salutation: "Salutation",
    voucherCode: "Voucher code",
    voucherExpirationDate: "Voucher code expiration date",
    isRequired: "is required",
    useForAls: "use for ALS",
    resultsPerPage: "Results Per Page ",
    save: "Save",
    goToList: "Go to List",
    german: "German",
    english: "English",
    invite: "Invite",
    unavailable: "Unavailable",
    adminstration: "Administration",
    delete: "Deletion successfull",
};
