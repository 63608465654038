import {
    CLEAR_SELECTIONS,
    SET_SELECT,
    REMOVE_SELECT,
    SELECT_ALL,
    SET_CURRENT_PAGE,
    SET_REMOVE_SELECT,
    RESET_SELECTS,
    REFRESH_SELECTS,
} from "../core_actions/types";

const initialState = {
    selects: false,
    selectsAll: false,
    removeSelects: {},
    currentPage: 1,
    refresh: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REFRESH_SELECTS:
            return {
                ...state,
                refresh: !state.refresh,
            };
        case RESET_SELECTS:
            return {
                ...initialState,
            };
        case CLEAR_SELECTIONS:
            return {
                ...initialState,

                selects: {},
                selectsAll: false,
            };
        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
            };
        case SET_SELECT:
            return {
                ...state,
                selects: !state.selects,
            };
        case SET_REMOVE_SELECT:
            return {
                ...state,
                removeSelects: action.payload,
            };
        case REMOVE_SELECT:
            return {
                ...state,
                selects: action.payload,
            };
        case SELECT_ALL:
            return {
                ...state,
                selectsAll: action.payload,
            };
        default:
            return state;
    }
}
