/*eslint-disable*/
import { SET_LOADER } from './types';
import _ from 'lodash'


export const setLoader = (val) => {
    return (dispatch) => {
        dispatch({
            type: SET_LOADER,
            payload: val
        });
    }
}