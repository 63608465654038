import {
    FETCH_WORKSPACES,
    DELETE_WORKSPACE,
    GET_CURRENT_WORKSPACE,
    SELECT_WORKSPACE,
    FETCH_ALL_WORKSPACES,
    LOADER_WORKSPACES_LIST,
    LOADER_WORKSPACE_SELECTION,
    LOADER_CURRENT_WORKSPACE_GET,
} from "./types";
import commonService from "../core_services/commonService";
import axios from "../core_services/interceptor";

import { fetchUsers } from "./usersActions";

const headers = {
    Accept: "application/json",
    Authorization: `Bearer ${sessionStorage.getItem("access_token")}`,
};

export const fetchWorkspaces = (page = 1) => {
    let size = commonService.getTotalResultsPerPage("workspace");
    return (dispatch) => {
        dispatch({ type: LOADER_WORKSPACES_LIST });
        axios
            .get(
                commonService.baseUrlWithoutWorkspace +
                    "workspaces?page=" +
                    page +
                    "&size=" +
                    size,
                {
                    headers: headers,
                }
            )
            .then(function (response) {
                dispatch({
                    type: FETCH_WORKSPACES,
                    payload: response.status === 200 ? response.data : {},
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const fetchAllWorkspaces = () => {
    return (dispatch) => {
        axios
            .get(commonService.baseUrlWithoutWorkspace + "workspaces", {
                headers: headers,
            })
            .then(function (response) {
                dispatch({
                    type: FETCH_ALL_WORKSPACES,
                    payload: response.status === 200 ? response.data : {},
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
};

export const getCurrentWorkspace = () => {
    return (dispatch) => {
        dispatch({ type: LOADER_CURRENT_WORKSPACE_GET, payload: true });
        let currentWorkspace = {
            data: [],
        };
        axios
            .get(
                commonService.baseUrlWithoutWorkspace +
                    "workspaces/currentWorkspaces",
                {
                    headers: headers,
                }
            )
            .then(function (response) {
                currentWorkspace.data = response ? response.data : undefined;
                if (
                    currentWorkspace.data &&
                    currentWorkspace.data.name === undefined
                ) {
                    currentWorkspace.data = { error: true };
                } else if (currentWorkspace.data !== undefined) {
                    dispatch({
                        type: LOADER_CURRENT_WORKSPACE_GET,
                        payload: false,
                    });
                }
                sessionStorage.setItem(
                    "workspace",
                    currentWorkspace.data ? currentWorkspace.data.id : ""
                );
                dispatch({
                    type: GET_CURRENT_WORKSPACE,
                    payload: currentWorkspace,
                });
            });
    };
};

export const selectWorkspace = (id) => {
    return (dispatch) => {
        dispatch({ type: LOADER_WORKSPACE_SELECTION });
        let selectedWorkspace = {
            data: [],
        };
        axios
            .post(
                commonService.baseUrlWithoutWorkspace +
                    id +
                    "/currentWorkspaces",
                {},
                {
                    headers: headers,
                }
            )
            .then(function (response) {
                selectedWorkspace.data = response ? response.data : [];
                console.log(selectedWorkspace);
                sessionStorage.setItem(
                    "workspace",
                    response && response.data ? response.data.id : ""
                );
                dispatch({
                    type: SELECT_WORKSPACE,
                    payload: selectedWorkspace,
                });
            })
            .then(() => {
                dispatch(fetchUsers());
            });
    };
};

export const deleteWorkspace = (id) => async (dispatch) => {
    dispatch({ type: LOADER_WORKSPACES_LIST });
    await axios
        .delete(commonService.getBaseUrl() + id, { headers })
        .then((response) => {
            dispatch({
                type: DELETE_WORKSPACE,
                payload: {
                    data: {
                        id: id,
                        deleteStatus: true,
                        response: response,
                    },
                },
            });
        });
};
