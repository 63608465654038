import { sygnet } from "./sygnet";
import { logo } from "./logo";
import { logoNegative } from "./logo-negative";

import {
    cilApplicationsSettings,
    cilSpeedometer,
    cilSun,
    cilMoon,
    cilUser,
    cilPeople,
    cifDe,
    cifGb,
    cilLayers,
    cilLifeRing,
    cilClipboard,
    cilLibraryAdd,
    cilX,
    cilBrushAlt,
    cilColumns,
    cilFolderOpen,
    cilLoop,
    cilSitemap,
    cilPencil,
    cilTrash,
    cilPlus,
    cilSettings,
    cilCloud,
    cilArrowLeft,
    cilList,
    cilArrowCircleTop,
    cilCheckCircle,
    cilLockLocked,
    cilCaretBottom,
    cilCaretTop,
    cilLibrary,
    cibCss3,
    cilViewQuilt,
    cilInputPower,
    cilHome,
    cilBarcode,
    cilFullscreen,
    cilCode,
    cilAppsSettings,
    cilAlignLeft,
    cilAlignCenter,
    cilAlignRight,
    cilTextSquare,
    cilEnvelopeLetter,
    cilCalendar,
    cilEqualizer,
    cilListNumbered,
    cilLineStyle,
} from "@coreui/icons";

export const icons = Object.assign(
    {},
    {
        sygnet,
        logo,
        logoNegative,
    },
    {
        cilApplicationsSettings,
        cilSpeedometer,
        cilSun,
        cilMoon,
        cilUser,
        cifDe,
        cifGb,
        cilPeople,
        cilLayers,
        cilLifeRing,
        cilClipboard,
        cilLibraryAdd,
        cilX,
        cilBrushAlt,
        cilColumns,
        cilFolderOpen,
        cilLoop,
        cilSitemap,
        cilPencil,
        cilTrash,
        cilPlus,
        cilSettings,
        cilCloud,
        cilArrowLeft,
        cilList,
        cilArrowCircleTop,
        cilCheckCircle,
        cilLockLocked,
        cilCaretBottom,
        cilCaretTop,
        cilLibrary,
        cibCss3,
        cilViewQuilt,
        cilInputPower,
        cilHome,
        cilBarcode,
        cilFullscreen,
        cilCode,
        cilAppsSettings,
        cilAlignLeft,
        cilAlignCenter,
        cilAlignRight,
        cilTextSquare,
        cilEnvelopeLetter,
        cilCalendar,
        cilEqualizer,
        cilListNumbered,
        cilLineStyle,
    }
);
