import { CREATE_USER, FETCH_USER, EDIT_USER, CHANGE_PASSWORD_STATUS, CLEAN_USER_FORM, REGISTER_USER } from '../core_actions/types';

const initialState = {
    user: {},
    createdUser: {},
    editedUser: {},
    changePasswordStatus: null,
    registeredUser: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAN_USER_FORM:
            return {
                ...state,
                user: {},
                createdUser: {},
                editedUser: {},
                changePasswordStatus: null
            }
        case CREATE_USER:
            return {
                ...state,
                createdUser: action.payload
            }
        case FETCH_USER:
            return {
                ...state,
                user: action.payload
            }
        case EDIT_USER:
            return {
                ...state,
                editedUser: action.payload
            }
        case CHANGE_PASSWORD_STATUS: {
            return {
                ...state,
                changePasswordStatus: action.payload
            }
        }
        case REGISTER_USER: {
            return {
                ...state,
                registeredUser: action.payload
            }
        }
        default:
            return state;
    }
}
