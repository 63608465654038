import {
    FETCH_CONTENT_TYPE,
    FETCH_CONTENT_TYPES,
    FETCH_CURRENT_CONTENT_TYPES,
    SORT_CONTENT_TYPES,
    SEARCH_CONTENT_TYPES,
    CREATE_CONTENT_TYPE,
    CREATE_CONTENT_TYPE_FIELDS,
    DELETE_CONTENT_TYPE,
    DELETE_CONTENT_TYPES,
    FETCH_CONTENT_TYPE_FIELD,
    UPDATE_CONTENT_TYPE,
    CLEAN_CONTENT_TYPES,
    SET_CONTENT_TYPE_STATUS,
    SET_READY,
    SET_NEW_CONTENT_TYPE_FIELDS,
    SET_NEW_CONTENT_TYPE_FIELD,
    UPDATE_CONTENT_TYPE_FIELDS,
    SET_LABEL_FIELD,
    SET_CONTENT_TYPE_FORM_DATA,
    SET_CONTENT_TYPE_FIELD_DATA,
    DELETE_ALL_CONTENT_TYPES,
    DELETE_ALL_CONTENT_TYPE_FIELDS,
    CLEAR_CONTENT_TYPE,
    CLEAR_DELETE_CONTENT_TYPE,
    CLEAR_DELETE_CONTENT_TYPES,
    LOADER_CONTENT_TYPE_FORM,
    LOADER_CONTENT_TYPE_LIST,
} from "../Actions/types";

const initialState = {
    requestStatus: -1,
    contentTypes: {},
    contentType: {},
    contentTypeFields: [],
    newContentTypeField: {},
    newContentTypeFields: [],
    editedContentType: {},
    deleteFieldStatus: -1,
    createdContentType: {},
    labelField: {},
    newContentType: {},
    deletedContentType: {},
    deletedContentTypes: [],
    formData: [],
    fieldData: {},
    ready: {},
    deletedAllContentTypeFields: null,
    loaderContentTypeForm: false,
    loaderContentTypeList: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAN_CONTENT_TYPES:
            return {
                ...state,
                requestStatus: -1,
                contentType: {},
                contentTypeFields: [],
                newContentTypeFields: [],
                editContentTypeFields: [],
                deleteContentTypeFields: [],
                editedContentType: {},
                deleteFieldStatus: -1,
                createdContentType: {},
                labelField: {},
                newContentType: {},
                deletedContentType: {},
                deletedContentTypes: [],
                formData: [],
                fieldData: {},
                ready: {},
            };
        case DELETE_ALL_CONTENT_TYPES:
            return {
                ...state,
                deletedContentTypes: action.payload,
                loaderContentTypeList: false,
            };
        case DELETE_ALL_CONTENT_TYPE_FIELDS:
            return {
                ...state,
                deletedAllContentTypeFields: action.payload,
            };
        case SET_CONTENT_TYPE_FORM_DATA: {
            return {
                ...state,
                formData: action.payload,
            };
        }
        case SET_READY: {
            return {
                ...state,
                ready: action.payload,
            };
        }
        case SET_CONTENT_TYPE_FIELD_DATA: {
            return {
                ...state,
                fieldData: action.payload,
            };
        }
        case FETCH_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload,
                loaderContentTypeForm: false,
            };
        case SET_CONTENT_TYPE_STATUS:
            return {
                ...state,
                requestStatus: action.payload,
            };
        case FETCH_CONTENT_TYPES:
            return {
                ...state,
                contentTypes: action.payload,
                contentTypesFromResponse: action.payload.data,
                loaderContentTypeList: false,
            };
        case FETCH_CURRENT_CONTENT_TYPES:
            return {
                ...state,
                currentContentTypes: action.payload,
            };
        case SORT_CONTENT_TYPES:
            return {
                ...state,
                sortedContentTypes: action.payload,
            };
        case SEARCH_CONTENT_TYPES:
            return {
                ...state,
                filteredContentTypes: action.payload,
            };
        case CREATE_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload,
                loaderContentTypeForm: false,
            };
        case UPDATE_CONTENT_TYPE:
            return {
                ...state,
                contentType: action.payload,
            };
        case CREATE_CONTENT_TYPE_FIELDS:
            return {
                ...state,
                newContentTypeFields: action.payload,
            };
        case DELETE_CONTENT_TYPE:
        case CLEAR_DELETE_CONTENT_TYPE: {
            return {
                ...state,
                deletedContentType: action.payload,
                loaderContentTypeList: false,
            };
        }
        case DELETE_CONTENT_TYPES:
        case CLEAR_DELETE_CONTENT_TYPES: {
            return {
                ...state,
                deletedContentTypes: action.payload,
                loaderContentTypeList: false,
            };
        }
        case FETCH_CONTENT_TYPE_FIELD:
            return {
                ...state,
                contentTypeFields: action.payload,
            };
        case SET_NEW_CONTENT_TYPE_FIELDS: {
            return {
                ...state,
                newContentTypeFields: action.payload,
            };
        }
        case SET_NEW_CONTENT_TYPE_FIELD: {
            return {
                ...state,
                newContentTypeField: action.payload,
            };
        }
        case UPDATE_CONTENT_TYPE_FIELDS: {
            return {
                ...state,
                editContentTypeFields: action.payload,
            };
        }
        case CLEAR_CONTENT_TYPE: {
            return {
                ...state,
                contentType: {},
            };
        }
        case SET_LABEL_FIELD: {
            return {
                ...state,
                labelField: action.payload,
            };
        }
        case LOADER_CONTENT_TYPE_FORM:
            return {
                ...state,
                loaderContentTypeForm: true,
            };
        case LOADER_CONTENT_TYPE_LIST:
            return {
                ...state,
                loaderContentTypeList: true,
            };
        default:
            return state;
    }
}
