/* eslint-disable */
import React from "react";
// eslint-disable-next-line
import { FormattedMessage } from "react-intl";
import core_routes from "./core/core_routes";
import _ from "lodash";

const content = React.lazy(() => import("./cms/components/Contents/Content"));
const Connector = React.lazy(() =>
    import("./cms/components/Connector/Connector")
);
const contentTypes = React.lazy(() =>
    import("./cms/components/ContentTypes/ContentTypes")
);
const formContainer = React.lazy(() =>
    import("./cms/components/Forms/FormContainer")
);
const formContentType = React.lazy(() =>
    import("./cms/components/ContentTypes/ContentTypeFormContainer")
);
const contentImport = React.lazy(() => import('./cms/components/Contents/ContentImport'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    // { path: '/', exact: true, name: <FormattedMessage id="home" defaultMessage="Home" /> },
    {
        path: "/",
        exact: true,
        name: <FormattedMessage id="home" defaultMessage="Home" />
    },
    {
        path: "/contents",
        exact: true,
        name: <FormattedMessage id="content" defaultMessage="Contents" />,
        component: content,
    },
    {
        path: "/connector",
        exact: true,
        name: <FormattedMessage id="content" defaultMessage="Contents" />,
        component: Connector,
    },
    {
        path: "/contents/import",
        exact: true,
        name: <FormattedMessage id="content" defaultMessage="Contents" />,
        component: contentImport,
    },
    {
        path: "/contentTypes",
        exact: true,
        name: (
            <FormattedMessage
                id="contentTypes"
                defaultMessage="Content Types"
            />
        ),
        component: contentTypes,
    },
    {
        path: "/contentTypes/edit/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="contentType.edit"
                defaultMessage="Content Types"
            />
        ),
        component: formContentType,
    },
    //{ path: '/contentTypes', exact: true, name: <FormattedMessage id="contentTypes" defaultMessage="Content Types" />, component: contentTypes },
    {
        path: "/contentTypes/create",
        exact: true,
        name: (
            <FormattedMessage
                id="newContentType"
                defaultMessage="New Content Type"
            />
        ),
        component: formContentType,
    },
    {
        path: "/contents/create/:id",
        exact: true,
        name: (
            <FormattedMessage
                id="content.create"
                defaultMessage="Create Content"
            />
        ),
        component: formContainer,
    },
    {
        path: "/contents/edit/:contentId&:id",
        exact: true,
        name: (
            <FormattedMessage id="content.edit" defaultMessage="Edit Content" />
        ),
        component: formContainer,
    },
];

const combinedRoutes = _.concat(core_routes, routes);
export default combinedRoutes;
