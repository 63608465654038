import { SET_STATUS, REMOVE_STATUS } from '../core_actions/types';

const initialState = {
    status: -1
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_STATUS:
            return {
                ...state,
                status: action.payload
            }
        case REMOVE_STATUS:
            return {
                ...state,
                status: -1
            }
        default:
            return state;
    }
}
