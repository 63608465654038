import React from "react";
// eslint-disable-next-line
import { FormattedMessage } from "react-intl";

const workspaces = React.lazy(() =>
    import("../core_components/Workspaces/Workspaces")
);
const workspaceForm = React.lazy(() =>
    import("../core_components/Workspaces/WorkspaceForm")
);
const users = React.lazy(() => import("../core_components/Users/Users"));
const userInvite = React.lazy(() =>
    import("../core_components/Users/UserInvite")
);
const Dev = React.lazy(() => import("../core_components/Dev/DevComponent"));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
    {
        path: "/workspaces",
        exact: true,
        name: <FormattedMessage id="workspaces" defaultMessage="Workspaces" />,
        component: workspaces,
    },
    {
        path: "/workspace/create",
        exact: true,
        name: (
            <FormattedMessage
                id="workspace.create"
                defaultMessage="Create Workspace"
            />
        ),
        component: workspaceForm,
    },
    {
        path: "/workspace/edit/:workspaceId",
        exact: true,
        name: (
            <FormattedMessage
                id="workspace.edit"
                defaultMessage="Edit Workspace"
            />
        ),
        component: workspaceForm,
    },
    {
        path: "/users",
        exact: true,
        name: <FormattedMessage id="user.list" defaultMessage="User List" />,
        component: users,
    },
    {
        path: "/users/invite",
        exact: true,
        name: (
            <FormattedMessage id="user.invite" defaultMessage="Invite User" />
        ),
        component: userInvite,
    },
    { path: "/dev", exact: true, name: "DEV", component: Dev },
];

export default routes;
