import {
    FETCH_CURRENT_CHILD_CONTENTS,
    FETCH_CHILD_CONTENTS,
    SEARCH_CHILD_CONTENTS,
    SORT_CHILD_CONTENTS,
    SELECTED_CHILD_CONTENTS,
    FETCH_CONTENT_CHILD_CONTENTS,
    LOADER_CHILD_CONTENTS,
} from "../Actions/types";

const initialState = {
    requestStatus: {},
    contents: [],
    mappedChildContents: [],
    loaderChildContents: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_CHILD_CONTENTS:
            return {
                ...state,
                contents: action.payload,
                loaderChildContents: false,
            };
        case FETCH_CONTENT_CHILD_CONTENTS:
            return {
                ...state,
                mappedChildContents: action.payload,
                loaderChildContents: false,
            };
        case FETCH_CURRENT_CHILD_CONTENTS:
            return {
                ...state,
                currentChildContents: action.payload,
            };
        case SORT_CHILD_CONTENTS:
            return {
                ...state,
                sortedChildContents: action.payload,
            };
        case SEARCH_CHILD_CONTENTS:
            return {
                ...state,
                filteredChildContents: action.payload,
            };

        case SELECTED_CHILD_CONTENTS:
            return {
                ...state,
                selectedChildContents: action.payload,
            };
        case LOADER_CHILD_CONTENTS:
            return {
                ...state,
                loaderChildContents: true,
            };
        default:
            return state;
    }
}
