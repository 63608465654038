import { SAVE_FORM, UNSAFE_FORM, SET_FORM_INPUT, CREATE_CONTENT, UPDATE_FORM_CONTENTS, INIT_FORM_VALIDATION, UPDATE_FORM_VALIDATION, SET_LOADER_VALUE } from '../Actions/types';

const initialState = {
    save: false,
    input: {},
    formContents: {},
    validForm: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_FORM:
            return {
                ...state,
                save: action.payload
            }
        case UNSAFE_FORM:
            return {
                ...state,
                save: action.payload
            }
        case SET_FORM_INPUT: return {
            ...state,
            input: action.payload
        }
        case UPDATE_FORM_CONTENTS:
            return {
                ...state,
                formContents: action.payload
            }
        case CREATE_CONTENT:
            return {
                ...state,
                contentCreated: action.payload
            }
        case INIT_FORM_VALIDATION:
            return {
                ...state,
                validForm: action.payload
            }
        case UPDATE_FORM_VALIDATION:
            return {
                ...state,
                validForm: action.payload
            }
        case SET_LOADER_VALUE:
            return {
                ...state,
                loaderValue: action.payload
            }
        default:
            return state;
    }
}
