import {
    CREATE_WORKSPACE,
    FETCH_WORKSPACE,
    EDIT_WORKSPACE,
    CLEAR_WORKSPACE_FORM,
    LOADER_WORKSPACE_FORM,
} from "../core_actions/types";

const initialState = {
    createdWorkspace: {},
    workspace: {},
    editedWorkspace: {},
    loaderWorkspaceForm: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_WORKSPACE_FORM:
            return {
                createdWorkspace: {},
                workspace: {},
                editedWorkspace: {},
                loaderWorkspaceForm: false,
            };
        case CREATE_WORKSPACE:
            return {
                ...state,
                workspace: action.payload,
                loaderWorkspaceForm: false,
            };
        case FETCH_WORKSPACE:
            return {
                ...state,
                workspace: action.payload,
                loaderWorkspaceForm: false,
            };
        case EDIT_WORKSPACE:
            return {
                ...state,
                workspace: action.payload,
                loaderWorkspaceForm: false,
            };
        case LOADER_WORKSPACE_FORM:
            return {
                ...state,
                loaderWorkspaceForm: true,
            };
        default:
            return state;
    }
}
