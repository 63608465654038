
import 'spinkit/spinkit.min.css';
import React from 'react'

const FoldCube = () => {
    return (
        <div className="autlay-fold-cube-wrapper">
            <div className="sk-fold autlay-fold-cube">
                <div className="autlay-fold-item autlay-fold-item-secondary sk-fold-cube"></div>
                <div className="autlay-fold-item autlay-fold-item-secondary sk-fold-cube"></div>
                <div className="autlay-fold-item autlay-fold-item-primary sk-fold-cube"></div>
                <div className="autlay-fold-item autlay-fold-item-primary sk-fold-cube"></div>
            </div>
        </div>
    )
}

export default FoldCube;
