import localeReducer from "./localeReducer";
import requestReducer from "./requestReducer";
import formReducer from "./formReducer";
import workspaceReducer from "./workspaceReducer";
import workspaceFormReducer from "./workspaceFormReducer";
import usersReducer from "./usersReducer";
import userFormReducer from "./userFormReducer";
import tableReducer from "./tableReducer";
import coreUiReducer from "./coreUiReducer";
import loaderReducer from "./loaderReducer";
import toasterReducer from "./toasterReducer";

const coreReducer = {
    locale: localeReducer,
    request: requestReducer,
    form: formReducer,
    workspaceForm: workspaceFormReducer,
    workspaces: workspaceReducer,
    users: usersReducer,
    userForm: userFormReducer,
    table: tableReducer,
    core: coreUiReducer,
    loader: loaderReducer,
    toaster: toasterReducer,
};

export default coreReducer;
