/* eslint-disable */

import axios from "axios";
import _ from "lodash";
import React from "react";
import ToastContainer from "../core_components/Toast/ToastContainer";
import tokenHandling from "./tokenHandling";
const env = process.env.NODE_ENV;
let appContext;
let formContainerContext;
let defaultLayoutContenxt;
let access_token_expires_in;
const defaultItemsPerPage = 10;
let dummyChapterData = {};
let dummySectionData = {};
const uri = window._env_
    ? window._env_.REACT_APP_HOSTNAME
    : process.env.REACT_APP_HOSTNAME;
const apiUrl = window._env_
    ? window._env_.REACT_APP_API_DOMAIN
    : process.env.REACT_APP_API_DOMAIN;
const authUrl = window._env_
    ? window._env_.REACT_APP_AUTH_DOMAIN
    : process.env.REACT_AUTH_DOMAIN;
const realm = window._env_
    ? window._env_.REACT_APP_KEYCLOAK_REALM
    : process.env.REACT_KEYCLOAK_REALM;
const workspaceId = window._env_
    ? window._env_.REACT_APP_CLIENT_ID
    : process.env.REACT_APP_CLIENT_ID;

const CommonService = {
    baseUrlWithoutWorkspace: apiUrl + "api/v1/",
    webSocketUrl: apiUrl + "ws/v1/",
    authCodeEndpoint:
        authUrl + "realms/" + realm + "/protocol/openid-connect/auth",
    authTokenEndpoint:
        authUrl + "realms/" + realm + "/protocol/openid-connect/token",
    authRegistrationEndpoint:
        authUrl +
        "realms/" +
        realm +
        "/protocol/openid-connect/registrations?client_id=" +
        workspaceId +
        "&response_type=code&redirect_uri=" +
        uri,
    authLogoutEndpoint:
        authUrl + "realms/" + realm + "/protocol/openid-connect/logout",
    selectedWorkspace: sessionStorage.getItem("workspace"),

    ...tokenHandling,

    getBaseUrlAndWorkspace: function () {
        if (sessionStorage.getItem("workspace")) {
            return (
                apiUrl + "api/v1/" + sessionStorage.getItem("workspace") + "/"
            );
        } else {
            return setTimeout(() => this.getBaseUrlAndWorkspace(), 5000);
        }
    },

    getBaseUrl: function () {
        return apiUrl + "api/v1/";
    },

    getCurrentWorkspace: function () {
        axios
            .get(this.baseUrlWithoutWorkspace + "user/workspace/current", {
                headers: {
                    Accept: "application/json",
                    Authorization: `Bearer ${sessionStorage.getItem(
                        "access_token"
                    )}`,
                },
            })
            .then(function (response) {
                sessionStorage.setItem("workspace", response.data.id);
                return response.data.id;
            });
    },

    setAppContext: function (context) {
        appContext = context;
    },
    setFormContainerContext: function (context) {
        formContainerContext = context;
    },
    getDefaultLayoutContext: () => {
        return defaultLayoutContenxt;
    },
    setDefaultLayoutContext: (context) => {
        defaultLayoutContenxt = context;
    },
    getAppContext: function () {
        return appContext;
    },
    getFormContainerContext: function () {
        return formContainerContext;
    },
    toaster: (type, value) => {
        return <ToastContainer _type={type} _value={value} />;
    },

    getDefaultItemsPerPage: () => defaultItemsPerPage,
    getTotalResultsPerPage: (resource) => {
        let pagination = JSON.parse(sessionStorage.getItem("pagination"));
        if (
            pagination === null ||
            pagination.itemsPerPage[resource] === undefined
        ) {
            return defaultItemsPerPage;
        } else {
            return pagination.itemsPerPage[resource];
        }
    },
    setTotalResultsPerPage: (size, resource) => {
        let pagination = JSON.parse(sessionStorage.getItem("pagination"));
        pagination.itemsPerPage[resource] = size;
        sessionStorage.setItem("pagination", JSON.stringify(pagination));
    },
    getIds: (selects) => {
        let data = [];
        if (selects !== undefined)
            _.each(selects, (val, key) => {
                if (val !== undefined) {
                    data = _.concat(
                        data,
                        val.id === undefined ? val[0].id : val.id
                    );
                }
            });
        return data;
    },
    setDummyChapterData: (sendoutId) => {
        return (dummyChapterData = {
            content: [
                {
                    id: null,
                    name: "Default Chapter",
                    css: null,
                    minPages: 1,
                    maxPages: 1,
                    startingPageNumber: null,
                    startingPage: null,
                    sendoutId: sendoutId,
                    cssFileId: null,
                    contentGroups: [],
                    contentSelectorTypeId: null,
                },
            ],
            pageable: {
                sort: {
                    sorted: false,
                    unsorted: true,
                    empty: true,
                },
                pageNumber: 0,
                pageSize: 10,
                offset: 0,
                unpaged: false,
                paged: true,
            },
            last: true,
            totalElements: 1,
            totalPages: 1,
            sort: {
                sorted: false,
                unsorted: true,
                empty: true,
            },
            numberOfElements: 1,
            first: true,
            size: 10,
            number: 0,
            empty: false,
        });
    },
    getDummyChapterData: () => {
        return dummyChapterData;
    },
    setDummySectionData: (sendoutPageId) => {
        return (dummySectionData = {
            content: [
                {
                    id: null,
                    name: "Default Section",
                    sendoutPageId: sendoutPageId,
                    contentGroup2ContentRefs: [],
                    contentSelectorTypeId: null,
                },
            ],
            pageable: {
                sort: { sorted: false, unsorted: true, empty: true },
                pageNumber: 0,
                pageSize: 10,
                offset: 0,
                unpaged: false,
                paged: true,
            },
            last: true,
            totalElements: 1,
            totalPages: 1,
            sort: { sorted: false, unsorted: true, empty: true },
            numberOfElements: 1,
            first: true,
            size: 10,
            number: 0,
            empty: false,
        });
    },
    getDummySectionData: () => {
        return dummySectionData;
    },
};

export default CommonService;
