/* eslint-disable */
import { LOCALE_SET, SET_WINDOW_SIZE } from './types';

export const localeSet = lang => ({
    type: LOCALE_SET,
    lang: lang
})

export const setLocale = lang => (dispatch) => {
    localStorage.setItem("lang", lang);
    dispatch(localeSet(lang));
}

export const setWindowSize = size => dispatch => {
    dispatch({
        type: SET_WINDOW_SIZE,
        payload: { 'size': size, 'isMobile': size < 768 }
    })
}

