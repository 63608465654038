import { SET_TOASTER, HANDLE_MODAL } from "./types";

export const setToaster = (type, value) => {
    return (dispatch) => {
        dispatch({
            type: SET_TOASTER,
            payload: {
                type: type,
                value: value,
            },
        });
    };
};

export const handleModal = (bool, error) => {
    return (dispatch) => {
        dispatch({
            type: HANDLE_MODAL,
            payload: { value: bool, error: error },
        });
    };
};
