/* eslint-disable */
export default {
    /**
     * Content
     */
    content: "Contents",
    contents: "Contents",
    contentSelector: "Recommendations",
    "contents.list": "Content List",
    "contents.edit": "Edit Content",
    "contents.create": "Create a new content",
    "contents.import": "Import contents from an Excel file",
    "contents.delete": "Delete content",
    "contents.select": "Selected content",
    "placeHolder.content.search": "Insert a search string",
    "contents.edited": "Content edited Successfully",
    "contents.deleted": "Content deleted Successfully",
    "contents.created": "Content created Successfully",
    childContent: "Child Contents",
    "deleteAll.contents": "Delete all contents",

    /**
     * Content-Type
     */
    contentType: "Contenttype",
    conentTypeField: "Contenttype Field",
    "contentType.delete": "Delete content type",
    "contentTypes.delete": "Delete content type",
    "contentTypes.deleteAll": "Delete all content types",
    contentTypeFieldName: "Contenttype field name",
    "contentType.list": "Content Type List",
    "contentTypes.edit": "Edit content type",
    "contentType.create": "Create a new content",
    "contentTypes.create": "Create a content of this type",
    "contentTypes.clear": "Delete all contents of this type",
    "contentTypes.import": "Import contents from an Excel file",
    "contentTypes.clearConfirm":
        "Are you sure you want to delete all contents of this type?",
    contentTypes: "Content Types",
    "deleteAll.contentTypeField": "Delete all content type fields",
    contentTypeSelection: "Select Content Type",

    /**
     * Connector
     */
    authorization: "Authorization",
    "connector.requestPermission":
        "Do you realy want to grant access to AutLay Campaign Management System?",
    "connector.selectWorkspace": "Select Workspace",
    "connector.authorize": "Authorize",
    "connector.close": "Close",
};
