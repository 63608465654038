import { combineReducers } from "redux";
import contentReducer from "./contentReducer";
import requestReducer from "./requestReducer";
import formReducer from "./formReducer";
import contentTypesReducer from "./contentTypesReducer";
import childContentsReducer from "./childContentsReducer";
import contentListDialogReducer from "./contentListDialogReducer";
import connectorReducer from "./connectorReducer";
import core_reducer from "../../core/core_reducer/index";

export default combineReducers({
    content: contentReducer,
    request: requestReducer,
    form: formReducer,
    contentTypes: contentTypesReducer,
    childContents: childContentsReducer,
    contentListDialog: contentListDialogReducer,
    connector: connectorReducer,
    ...core_reducer,
});
